@import '../../styles/variables';

.Range {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &__title {
    margin-bottom: 1.5em;
    font-weight: bold;
    letter-spacing: 1.5px;
  }

  .input-range {
    &__track--active {
      background-color: $dark-green;
    }

    &__slider {
      background-color: $dark-green;
      border: none;
      height: 0.65rem;
      width: 0.65rem;
      margin-left: -0.5rem;
      margin-top: -0.49rem;
    }

    &__label {
      font-family: Lato;
      font-size: 0.75rem;
      font-weight: 500;
      letter-spacing: 1.5px;
      color: black;

      &--min,
      &--max {
        display: none;
      }

      &--value {
        transform: translateY(3.5em);
      }
    }
  }
}
