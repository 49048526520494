@import '../../styles/variables';

.Map {
  width: 100%;
  height: 100%;
  position: relative;

  &__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__content {
    width: 100%;
    height: 100%;

    &Marker {
      &:hover {
        box-shadow: 0 2px 20px 0 rgba(131, 131, 131, 0.37);
      }

      &Link {
        display: flex;
        justify-content: center;
        padding: 0.5em 1em;
        color: $dark-green;

        &:hover {
          text-decoration: underline;
        }
      }

      .CoachingClassPreviewVertical {
        border-bottom: none;

        &:hover {
          box-shadow: none;
        }
      }
    }
  }

  &__zoomControls {
    position: absolute;
    top: 1.5em;
    right: 1.5em;
    z-index: 2;
    display: flex;
    flex-direction: column;
    width: 2em;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);

    &Plus,
    &Minus {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $dark-green;
      height: 32px;
      width: 32px;
      font-size: 1.75em;
      font-weight: 300;
      cursor: pointer;

      &:hover {
        font-weight: 500;
      }
    }

    &Separator {
      width: 100%;
      height: 0.5px;
      background-color: $light-gray;
    }
  }
}
