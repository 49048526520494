@import '../../../styles/variables';

.MapMarker {
  position: relative;
  width: 6em;
  height: 4em;
  padding: 1em 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 7px;
  box-shadow: $shadow;
  cursor: pointer;
  z-index: 10;

  img {
    height: 100%;
  }

  &__iconWrapper {
    height: 100%;
    width: 100%;
  }

  &__close {
    position: absolute;
    top: 1em;
    right: 1em;
    z-index: 2;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);

    svg {
      path {
        fill: black;
      }
    }
  }

  &--expanded {
    width: auto;
    height: auto;
    padding: 0;

    // transform: translateX(calc(-50% + 3em)) translateY(calc(-100% + 4em));
  }

  &:after {
    content: ' ';
    position: absolute;
    top: 99%;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);
    border-top: 5px solid white;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    box-shadow: $shadow;
  }

  .CoachPreview {
    flex-direction: column;
    align-items: center;
    width: 15em;

    &__photo {
      width: unset;
      margin-right: unset;
      margin-bottom: 1em;
    }
  }
}
