@import '../../styles/variables';

.PersonalInfos {
  max-width: 50em;

  &__title {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 3em;
  }

  &__form {
    &Row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 2em;

      &--alignedBottom {
        align-items: flex-end;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .InputWithLabel {
        flex-grow: 1;
        max-width: 23em;
        margin-right: 2em;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &Address {
      margin-bottom: 2em;
      width: 100%;
    }

    &PasswordChange {
      font-size: 0.75em;
      color: $dark-green;
      text-decoration: underline;
      letter-spacing: 0.3px;
      margin-top: 0.25em;
      cursor: pointer;
    }

    .rw-datetime-picker {
      flex-grow: 1;
      max-width: 23em;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4em;

    &Submit {
      background: $gradient;
      color: white;
      margin-bottom: 1em;
    }

    &DeleteAccount {
      color: gray;
      font-size: 0.75em;
      text-decoration: underline;
      letter-spacing: 0.3px;
      cursor: pointer;
    }
  }

  @media (max-width: 1000px) {
    &__form {
      display: flex;
      flex-direction: column;

      &Row {
        flex-direction: column;
        margin-bottom: 0;
        align-items: center;

        .InputWithLabel {
          margin-right: 0;
          margin-bottom: 2em;
          width: 100%;
        }

        .rw-datetime-picker {
          width: 100%;
          margin-bottom: 2em;
        }
      }

      &Address {
        align-self: center;
        max-width: 23em;
      }
    }
  }
}
