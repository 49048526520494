@import '../../../styles/variables';

.ChangePasswordForm {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100vw;
  background-color: rgba(247, 247, 247, 0.8);
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7em 25em;

  &__close {
    position: absolute;
    top: 2em;
    right: 2em;
    width: 1.5em;
    height: 1.5em;
    cursor: pointer;

    svg {
      width: 100%;
      height: auto;
    }
  }

  &__content {
    position: relative;
    background-color: white;
    max-width: 50em;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.26);
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    flex-direction: column;
    padding: 2em 5em;
    overflow-y: auto;

    &Body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      align-items: center;
      justify-content: center;

      &Title {
        color: $gray;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: bold;
        text-align: center;
        margin-top: 0.5em;
        margin-bottom: 3em;
      }

      .InputWithLabel {
        margin-bottom: 1em;
        width: 15em;

        &:last-child {
          margin-bottom: 2em;
        }
      }

      .Button {
        background-color: $dark-green;
        color: white;
      }
    }
  }

  @media (max-width: 1000px) {
    padding: 0;
  }
}
