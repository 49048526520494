@import './styles/variables';

html,
body {
  height: 100%;
}

* {
  box-sizing: border-box;
}

body {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  margin: 0;
}

i {
  font-family: 'Material Icons', 'Comic Sans Ms';
  text-decoration: none;
  font-style: normal;
  user-select: none;
}

a {
  text-decoration: none;
  color: inherit;
}

input {
  outline: none;
}

// GMaps Address search box
.pac-container {
  width: 20em !important;
}

.toastify {
  transform: translateY(5em);
}

select {
  -webkit-appearance: menulist-text;
}

h1,
h2 {
  font-size: unset;
}
