@import '../../styles/variables';

.CoachingClassPreviewHorizontal {
  display: flex;
  min-height: fit-content;
  width: 100%;
  letter-spacing: 0.5px;
  padding: 2em 1em;
  border-bottom: solid 0.5px $light-gray;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: rgba(126, 214, 97, 0.1);
  }

  &__photo {
    position: relative;
    width: 40%;
    height: 11em;
    margin-right: 2.5em;
    flex-shrink: 0;

    &Background {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      object-fit: cover;
    }

    &CategoryIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5em;
      width: 3em;
      height: 3em;
      position: absolute;
      top: 1em;
      left: 1em;
      border-radius: 50%;
      background-color: white;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

      img {
        width: 90%;
        height: 90%;
      }
    }
  }

  &__infos {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;

    &Left {
      flex-grow: 1;
      margin-right: 2em;

      &Coach {
        color: $gray;
        font-size: 0.8em;
        font-weight: 700;
        margin-bottom: 0.5em;
      }

      &Name {
        font-size: 1.25em;
        margin-bottom: 1em;
      }

      &Location {
        display: flex;
        color: $gray;
        font-size: 0.8em;
        font-weight: 700;
        margin-bottom: 1em;

        svg {
          height: 1.25em;
          width: auto;
          margin-right: 0.5em;
        }
      }

      &Dates {
        display: flex;
        flex-direction: column;
        color: $gray;
        font-size: 0.75em;
        margin-bottom: 1em;

        &Entry {
          margin-bottom: 0.5em;

          &:last-child {
            margin-bottom: 0;
          }

          span {
            color: black;
            text-transform: uppercase;
            margin-right: 0.5em;
          }

          svg {
            height: 1em;
            width: auto;
            margin-right: 0.5em;
          }
        }
      }

      &MaxAttendees {
        display: flex;
        color: $gray;
        font-size: 0.8em;
        margin-bottom: 1em;

        svg {
          height: 1em;
          width: auto;
          margin-right: 0.5em;
        }
      }
    }

    &Right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &Rating {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $dark-green;
        background-color: white;
        border-radius: 5px;
        border: 1px solid $dark-green;
        padding: 0.5em 1em;
        font-weight: 700;

        svg {
          margin-left: 0.5em;
        }
      }

      &Price {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        background-color: $dark-green;
        border-radius: 5px;
        padding: 0.5em 1em;
        font-weight: 700;
      }
    }
  }
}
