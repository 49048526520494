@import '../styles/variables';

.CoachingClassRegistration {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100vw;
  background-color: rgba(247, 247, 247, 0.8);
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7em 25em;

  &__loader {
    height: 100%;
    width: 100%;
    background-color: rgba(247, 247, 247, 0.8);
    position: absolute;
    z-index: 3;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
  }

  &__content {
    position: relative;
    background-color: white;
    max-width: 50em;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.26);
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    flex-direction: column;
    padding: 2em 5em 5em 5em;
    overflow-y: auto;

    &--loading {
      overflow: hidden;
    }

    &Header {
      $coach-image-width: 9em;
      position: relative;
      display: flex;
      padding: 2em 0;
      border-bottom: 1px solid $light-gray;
      font-size: 0.75em;
      flex-shrink: 0;

      &Coach {
        height: $coach-image-width;
        width: $coach-image-width;
        overflow: hidden;
        border-radius: 50%;
        border: 3px solid white;
        background-color: white;
        box-shadow: 0 5px 20px 0 rgba(76, 76, 76, 0.36);
        margin-right: 2em;
        flex-shrink: 0;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      &Desc {
        display: flex;
        flex-direction: column;

        &Coach {
          color: $gray;
          letter-spacing: 1px;
          text-transform: uppercase;
          font-size: 0.9em;
          font-weight: bold;
          margin-bottom: 0.75em;
          display: flex;
          flex-direction: column;

          span {
            margin-top: 0.5em;
            color: #485b6d;
            font-size: 0.75em;
            font-weight: bold;
            font-style: italic;
            letter-spacing: 0.55px;
          }
        }

        &Title {
          font-size: 2.75em;
          margin-bottom: 0.25em;
        }

        &Rating {
          display: flex;
          align-items: center;
          color: $dark-green;
          font-size: 1.1em;
          margin-bottom: 0.5em;

          &Value {
            font-weight: 900;
            margin-right: 0.5em;
            letter-spacing: 1px;
          }

          .react-rater {
            font-size: 1.1em;
            letter-spacing: 4px;
            margin-right: 0.5em;
          }

          &Feedbacks {
            font-size: 0.75em;
            letter-spacing: 0.65px;
            font-weight: 500;
            text-decoration: underline;
            margin-right: 2em;

            a,
            a:visited {
              color: $dark-green;
            }
          }

          &Networks {
            a {
              margin-right: 0.75em;
            }
          }
        }

        &Location {
          display: flex;
          font-weight: bold;
          color: $gray;
          align-items: center;
          margin-bottom: 2em;

          svg {
            height: 1em;
            width: auto;
            margin-right: 0.5em;
          }
        }

        &Book {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20em;
          height: 3em;
          color: white;
          font-family: Raleway;
          text-transform: uppercase;
          font-size: 0.9em;
          font-weight: bold;
          letter-spacing: 1.15px;
          text-align: center;
          background: $gradient;
          box-shadow: 0 2px 4px 0 rgba(74, 103, 136, 0.2);
          border-radius: 4px;
        }
      }

      &Price {
        width: 20%;

        &Content {
          position: absolute;
          right: -7em;
          top: 3em;
          background-color: $dark-green;
          color: white;
          padding: 1em 1.5em;
          border-radius: 5px 0 0 5px;
          width: 12em;

          span {
            font-size: 2em;
            font-weight: bold;
            letter-spacing: 0;
          }
        }
      }
    }

    &Stepper {
      padding: 2em 0;

      .stepper {
        background-color: transparent;
        box-shadow: none;
        border: none;
        font-family: Lato;
        overflow: visible;

        section:empty {
          display: none;
        }

        &__connector {
          color: $light-gray;
        }

        &__header {
          box-shadow: none;
          width: 70%;
          margin: auto;
        }

        &__head {
          padding: 0;
          z-index: 2;
          border-radius: 50%;
          height: fit-content;

          &:not(.stepper__head--disabled):hover {
            background-color: transparent;
          }

          &__index {
            color: $dark-green;
            background-color: white;
            box-shadow: 0 2px 5px 0 $dark-green;
            margin: 5px;

            svg path:last-child {
              fill: $dark-green;
            }
          }

          &--active {
            .stepper__head__index {
              background-color: $dark-green;
              color: white;
            }
          }
        }
      }
    }

    &Validated {
      .CoachingClassRegistration__contentHeader {
        border-bottom: none;

        &Desc {
          &Title {
            font-size: 2em;
          }

          &Location {
            font-size: 0.8em;
          }
        }
      }

      &Header {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 2.5em;
        border-bottom: 1px solid $light-gray;

        &Title {
          display: flex;
          align-items: center;
          font-size: 1.125em;
          margin-bottom: 1em;

          &svg {
            background-color: $dark-green;

            path {
              fill: white;
            }
          }
        }

        &Subtitle {
          font-size: 0.75em;
          color: $dark-green;
          font-weight: bold;
        }
      }

      &Content {
        padding-top: 2.5em;

        &Title {
          font-size: 0.875em;
          font-weight: bold;
          text-align: center;
        }

        &Header {
          border-bottom: none;
        }

        &Booking {
          &Title {
            display: flex;
            align-items: center;
            font-size: 0.85em;
            letter-spacing: 0.55px;
            text-transform: uppercase;
            margin-bottom: 1em;

            svg {
              margin-right: 0.5em;
            }
          }

          &Entries {
            padding-left: 1.375em;
          }

          &Entry {
            margin-bottom: 0.5em;
            display: flex;

            &:last-child {
              margin-bottom: 0;
            }

            &Date {
              font-size: 0.85em;
              font-style: italic;
              text-transform: capitalize;
            }

            &Separator {
              height: 100%;
              width: 1px;
              background-color: $dark-green;
              margin: 0 1em;
            }

            &Time {
              font-size: 0.85em;
              font-weight: bold;
              color: $dark-green;
            }

            a {
              margin-left: 1em;
              font-size: 0.8em;
              font-style: italic;
              text-decoration: underline;
              color: #4dba7f;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1000px) {
    padding: 0;

    &__content {
      &Header {
        flex-direction: column;
        align-items: center;
        font-size: 1.25em;

        &Price {
          &Content {
            right: -4em;
            width: auto;
          }
        }

        &Coach {
          margin-right: 0;
          margin-bottom: 1em;
        }

        &Desc {
          align-items: center;
          text-align: center;
        }
      }

      &Validated {
        font-size: 1.5em;
      }
    }
  }
}
