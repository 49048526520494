@import '../styles/variables';

.CoachDetails {
  display: flex;
  flex-direction: column;

  @media (max-width: 1600px) {
    font-size: 0.9em;
  }

  @media (max-width: 1400px) {
    font-size: 0.75em;
  }

  @media (max-width: 1200px) {
    font-size: 0.6em;
  }

  &__header {
    $coach-image-width: 11em;
    position: relative;
    display: flex;
    align-items: center;
    padding: 2em 0;

    &Images {
      position: relative;
      height: 18em;
      width: 40%;
      margin-right: $coach-image-width / 2 + 4em;

      &Coach {
        height: $coach-image-width;
        width: $coach-image-width;
        overflow: hidden;
        border-radius: 50%;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateX(50%) translateY(-50%);
        border: 3px solid white;
        background-color: white;
        box-shadow: 0 5px 20px 0 rgba(76, 76, 76, 0.36);
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    &Desc {
      display: flex;
      flex-direction: column;

      &Location {
        color: $gray;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-size: 0.9em;
        font-weight: bold;
        margin-bottom: 1em;
      }

      &Name {
        font-size: 2.75em;
        font-weight: bold;
        margin-bottom: 0.5em;
      }

      &Rating {
        display: flex;
        align-items: center;
        color: $dark-green;
        font-size: 1.1em;
        margin-bottom: 0.5em;

        &Value {
          font-weight: 900;
          margin-right: 0.5em;
          letter-spacing: 1px;
        }

        .react-rater {
          font-size: 1.1em;
          letter-spacing: 4px;
          margin-right: 0.5em;
        }

        &Feedbacks {
          font-size: 0.75em;
          letter-spacing: 0.65px;
          font-weight: 500;
          text-decoration: underline;
          margin-right: 2em;

          a,
          a:visited {
            color: $dark-green;
          }
        }

        &Networks {
          a {
            margin-right: 0.75em;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      &Location {
        display: flex;
        font-weight: bold;
        color: $gray;
        align-items: center;
        margin-bottom: 2em;

        svg {
          height: 1em;
          width: auto;
          margin-right: 0.5em;
        }
      }

      &Book {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20em;
        height: 3em;
        color: white;
        font-family: Raleway;
        text-transform: uppercase;
        font-size: 0.9em;
        font-weight: bold;
        letter-spacing: 1.15px;
        text-align: center;
        background: $gradient;
        box-shadow: 0 2px 4px 0 rgba(74, 103, 136, 0.2);
        border-radius: 4px;
        cursor: pointer;
        margin-top: 1em;
      }
    }
  }

  &__content {
    background-color: #f7f7f7;
    flex-grow: 1;
    padding: 4em 0;
    flex-shrink: 0;

    &Classes {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 5em;
      position: relative;

      &Title {
        font-size: 2em;
        letter-spacing: 1px;
        margin-bottom: 1.5em;

        span {
          font-weight: 300;
        }
      }

      &Controls {
        position: absolute;
        right: 10%;
        top: 0;

        svg {
          background-color: white;
          border-radius: 50%;
          cursor: pointer;
          overflow: hidden;

          &:hover {
            box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
          }

          &:first-child {
            margin-right: 1em;
            transform: rotate(180deg);
          }
        }
      }

      &Content {
        display: flex;

        a {
          margin-right: 2em;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    &Description {
      display: flex;
      padding: 3em 15em 0 15em;
      flex-shrink: 0;
      height: fit-content;

      &Left {
        flex: 2;
        display: flex;
        flex-direction: column;
        margin-right: 2em;
      }

      &Right {
        flex: 1;
        display: flex;
        flex-direction: column;
      }

      &Desc,
      &Qualifications {
        padding: 4em 5em;
        background-color: white;
        line-height: 1.75em;
        border-radius: 5px;
        margin-bottom: 2em;

        &Title {
          text-transform: uppercase;
          font-weight: bold;
          letter-spacing: 1.5px;
          margin-bottom: 2em;
          text-align: center;
        }

        &Content {
          letter-spacing: 0.5px;
          color: #485b6d;
        }
      }

      &Categories {
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 5px;
        padding: 1.5em 2em;
        margin-bottom: 2em;

        &Title {
          text-transform: uppercase;
          letter-spacing: 1.5px;
          text-align: center;
          font-weight: bold;
          margin-bottom: 2em;
        }

        &Content {
          /* gap: 1em; emulation (remove when gap is supported) */
          --gap: 1em;
          display: inline-flex;
          flex-wrap: wrap;
          margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
          width: calc(100% + var(--gap));

          & > * {
            margin: var(--gap) 0 0 var(--gap);
          }
          /* gap: 1em; emulation (remove when gap is supported) */
        }
      }

      &Medias {
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 5px;
        padding: 1.5em 2em;
        margin-bottom: 2em;

        &Content {
          height: 12em;
          width: 100%;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }

    &Reviews {
      padding: 0 15em 3em 15em;

      &Content {
        background-color: white;
        border-radius: 5px;
        padding: 4em 5em;
      }

      &Title {
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 1.5px;
        margin-bottom: 2em;
      }

      &Sumup {
        display: flex;
        align-items: baseline;
        color: $dark-green;
        margin-bottom: 3.5em;

        &Average {
          display: flex;
          align-items: center;
          font-size: 1.5em;
          font-weight: 900;
          margin-right: 1em;
          letter-spacing: 1.3px;

          span {
            margin-right: 0.5em;
          }
        }

        &Count {
          text-decoration: underline;
          letter-spacing: 0.9px;
        }
      }

      &Entries {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &Entry {
        display: flex;
        align-items: center;
        color: #485b6d;
        margin-bottom: 3em;
        width: 100%;

        &:last-child {
          margin-bottom: 0;
        }

        &Left {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 2em;
          width: 12.5em;

          &Photo {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 2.5em;
            width: 2.5em;
            border-radius: 50%;
            overflow: hidden;
            margin-bottom: 0.75em;

            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }

          &Name {
            letter-spacing: 0.8px;
            margin-bottom: 0.25em;
          }
        }

        &Right {
          font-size: 0.875em;
          font-style: italic;
        }
      }

      .pagination {
        display: flex;
        list-style: none;
        color: $dark-green;
        margin-bottom: 0;

        li {
          margin-right: 1em;
          cursor: pointer;

          &:last-child {
            margin-right: none;
          }

          &.active {
            text-decoration: underline;
          }

          &.disabled {
            color: $gray;
          }
        }
      }
    }
  }

  @media (max-width: 1000px) {
    &__header {
      font-size: 1.25em;
      flex-direction: column;
      padding: 2em 5%;

      &Images {
        display: flex;
        justify-content: center;
        height: auto;
        width: 100%;
        margin-right: 0;
        margin-bottom: 2em;

        &Coach {
          position: initial;
          top: unset;
          left: unset;
          transform: unset;
        }
      }

      &Desc {
        &Location {
          margin-bottom: 0.75em;
        }

        &Book {
          margin-top: 1em;
          width: 100%;
          padding: 1em 2em;
        }
      }
    }

    &__content {
      padding: 2em 5%;

      &Classes {
        font-size: 1rem;

        &Controls {
          display: none;
        }

        &Content {
          overflow-x: auto;
          width: 100%;
        }
      }

      &Description {
        padding: 0;
        flex-direction: column;

        &Left {
          margin-right: 0;
        }

        &Desc,
        &Qualifications {
          padding: 1.5em 2em;
        }

        &Categories {
          &Content {
            justify-content: center;
          }
        }
      }

      &Reviews {
        padding: 0;

        &Entry {
          flex-direction: column;
          margin-bottom: 3em;

          &Left {
            font-size: 1.25em;
            margin-right: 0;
            margin-bottom: 1em;
          }

          &Right {
            font-size: 1.25em;
          }
        }
      }
    }
  }
}
