@import '../../styles/variables';

#CoachInfos__billingMandate {
  margin-top: 5em;
}

.CoachInfos {
  max-width: 50em;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-size: 2em;
    font-weight: bold;
    width: 100%;
    margin-bottom: 0.5em;
  }

  &__subtitle {
    width: 100%;
    font-size: 1.25em;
    color: $gray;
    margin-bottom: 3em;
  }

  &__form {
    width: 100%;

    &Row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 2em;

      &:last-child {
        margin-bottom: 0;
      }

      .InputWithLabel {
        flex-grow: 1;
        max-width: 23em;
        margin-right: 2em;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &Address {
      margin-bottom: 2em;
      width: 100%;
    }

    &PasswordChange {
      font-size: 0.75em;
      color: $dark-green;
      text-decoration: underline;
      letter-spacing: 0.3px;
      margin-top: 0.25em;
      cursor: pointer;
    }
  }

  &__signBillingMandate {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    color: #4dba7f;
    padding: 0.75em 1.5em;
    background-color: white;
    border-radius: 5px;
    width: 100%;
    max-width: 23em;
    border: 1px solid #d2d2d2;
    cursor: pointer;

    &--filled {
      border: 3px solid $dark-green;
      cursor: initial;
    }

    svg {
      height: 1.75em;
      width: 1.75em;

      path {
        fill: #7ee8ae;
      }
    }

    span {
      font-size: 0.8em;
      letter-spacing: 0.9px;
    }
  }

  &__stripe {
    width: 100%;
    max-width: 23em;
    border-radius: 5px;
    background-color: white;
    border: 1px solid #d2d2d2;
    cursor: pointer;

    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-transform: uppercase;
      color: #4dba7f;
      padding: 0.75em 1.5em;
    }

    &--filled {
      border: 3px solid $dark-green;
      cursor: initial;
    }

    span {
      font-size: 0.8em;
      letter-spacing: 0.9px;
    }

    svg {
      height: 1.75em;
      width: 1.75em;

      path {
        fill: #7ee8ae;
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4em;

    &Submit {
      background: $gradient;
      color: white;
      margin-top: 1em;
    }

    &DeleteAccount {
      color: gray;
      font-size: 0.75em;
      text-decoration: underline;
      letter-spacing: 0.3px;
      cursor: pointer;
    }
  }

  .FileInput {
    max-width: 23em;
    box-shadow: none;
    border: 1px solid $light-gray;
    margin-right: 2em;

    &:last-child {
      margin-right: 0;
    }

    &--filled {
      border: 3px solid $dark-green;
    }
  }

  @media (max-width: 1000px) {
    &__form {
      display: flex;
      flex-direction: column;

      &Row {
        flex-direction: column;
        margin-bottom: 0;
        align-items: center;

        .FileInput,
        .CoachInfos__signBillingMandate,
        .CoachInfos__stripe {
          margin-right: 0;
          margin-bottom: 2em;
          width: 100%;
          max-width: unset;
          font-size: 1.5em;

          &__label,
          span {
            margin-right: 1em;
          }
        }
      }

      #CoachInfos__billingMandate {
        margin-top: 2em;
      }
    }

    &__actions {
      margin-top: 2em;
    }
  }
}
