@import '../../styles/variables';

.CoachingClassRegistrationSchedules {
  $calendar-size: 20em;

  &__title {
    color: $gray;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
    text-align: center;
    margin-top: 0.5em;
    margin-bottom: 3em;
  }

  &__calendar {
    width: $calendar-size;
    font-size: 0.75em;

    &Wrapper {
      display: flex;
    }

    margin-right: 3em;

    &Header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1em;

      &Label {
        text-transform: capitalize;
      }

      &Controls {
        svg {
          width: 2em;
          height: 2em;
          border-radius: 50%;
          cursor: pointer;
          overflow: hidden;

          &:hover {
            box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
          }

          &:first-child {
            margin-right: 0.5em;
            transform: rotate(180deg);
          }
        }
      }
    }

    .react-calendar {
      border: none;

      abbr[title] {
        color: $light-gray;
        font-size: 0.75em;
        text-decoration: none;
      }

      &__tile {
        height: calc(#{$calendar-size} * 1 / 7);
        width: calc(#{$calendar-size} * 1 / 7);
        border-radius: 50%;
        padding: 0.25em;
        font-size: 1em;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0.5em;

        // &--now {
        //   background-color: $dark-green;
        //   color: white;

        //   &:enabled:hover,
        //   &:enabled:focus {
        //     background-color: $dark-green;
        //     color: white;
        //   }
        // }

        &:enabled {
          color: $dark-green;
          font-weight: bold;
          border: 1px solid $dark-green;
        }

        &--active:enabled,
        &:enabled:hover,
        &:enabled:focus {
          background-color: $dark-green;
          color: white;
          font-weight: bold;
        }

        &:disabled {
          background-color: transparent;
          color: $gray;
        }
      }
    }
  }

  &__sessions {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;

    &Title {
      font-size: 0.8em;
      margin-bottom: 1em;
    }

    &Content {
      &Entry {
        color: $dark-green;
        border: 1px solid $dark-green;
        border-radius: 5px;

        &--full.Button {
          color: $pastel-red;
          border: 1px solid $pastel-red;
          background-color: white;
        }

        &--almostFull.Button {
          color: $pastel-yellow;
          border: 1px solid $pastel-yellow;
        }

        &--active {
          &.Button {
            color: white;
            background-color: $dark-green;
            border: none;
          }
        }
      }
    }
  }

  &__footer {
    margin-top: 2em;
    padding-top: 2em;
    border-top: 1px solid $light-gray;
    display: flex;
    flex-direction: column;
    align-items: center;

    &Title {
      font-size: 0.8em;
      margin-bottom: 1.5em;
    }

    &Sessions {
      margin-bottom: 2em;

      &Entry {
        display: flex;
        align-items: center;
        border: 1px solid $dark-green;
        height: 2.5em;
        width: 25rem;
        border-radius: 5px;
        margin-bottom: 0.5em;

        &:last-child {
          margin-bottom: 0;
        }

        &Remove {
          transform: translateY(-1px);
          padding: 0.5em 1em;
          cursor: pointer;

          svg {
            path {
              fill: $gray;
            }
          }
        }

        &Left,
        &Right {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 100%;
          flex-basis: 0;
          flex-grow: 1;
        }

        &Date {
          margin-left: 1em;
          font-size: 0.8em;
          font-style: italic;
          text-transform: capitalize;
        }

        &Time {
          margin-right: 1em;
          font-size: 0.8em;
          font-weight: bold;
          color: $dark-green;
        }

        &Price {
          display: flex;
          align-items: center;
          font-size: 0.9em;
          color: white;
          font-weight: 900;
          background-color: $dark-green;
          height: 100%;
          padding: 0.5em 1em;
        }

        &Separator {
          width: 1px;
          height: 100%;
          background-color: $dark-green;
          margin: 0 1em;
        }
      }
    }

    &Total {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      font-size: 1.25em;
      color: $dark-green;
      text-transform: uppercase;
      width: 25rem;
      margin-bottom: 2em;

      &Right {
        font-size: 1.25em;
      }
    }

    &Validate {
      .Button {
        color: white;
        background: $gradient;
        text-transform: uppercase;

        &--disabled {
          background: $light-gray;
        }
      }
    }
  }

  @media (max-width: 1000px) {
    font-size: 1.25em;

    &__footer {
      &Sessions {
        &Entry {
          width: 100%;
        }
      }

      &Total {
        width: 100%;
      }
    }
  }
}
