@import '../../styles/variables';

.CoachingClassRegistrationRecap {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    color: $gray;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
    text-align: center;
    margin-top: 0.5em;
    margin-bottom: 3em;
  }

  &__content {
    margin-bottom: 2em;

    &Entry {
      display: flex;
      align-items: center;
      border: 1px solid $dark-green;
      height: 2.5em;
      width: 25rem;
      border-radius: 5px;
      margin-bottom: 0.5em;

      &:last-child {
        margin-bottom: 0;
      }

      &Remove {
        transform: translateY(-1px);
        padding: 0.5em 1em;
        cursor: pointer;

        svg {
          path {
            fill: $gray;
          }
        }
      }

      &Left,
      &Right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        flex-basis: 0;
        flex-grow: 1;
      }

      &Date {
        margin-left: 1em;
        font-size: 0.8em;
        font-style: italic;
        text-transform: capitalize;
        padding: 0.25em 0;
      }

      &Time {
        margin-right: 1em;
        font-size: 0.8em;
        font-weight: bold;
        color: $dark-green;
      }

      &Price {
        display: flex;
        align-items: center;
        font-size: 0.9em;
        color: white;
        font-weight: 900;
        background-color: $dark-green;
        height: 100%;
        padding: 0.5em 1em;
      }

      &Separator {
        width: 1px;
        height: 100%;
        background-color: $dark-green;
        margin: 0 1em;
      }
    }

    &Add {
      color: $dark-green;
      font-size: 0.75em;
      font-weight: bold;
      margin-top: 1em;
      cursor: pointer;
    }
  }

  &__footer {
    &Total {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      font-size: 1.25em;
      color: $dark-green;
      text-transform: uppercase;
      width: 25rem;
      margin-bottom: 2em;

      &Right {
        font-size: 1.25em;
      }
    }

    &Validate {
      .Button {
        color: white;
        background: $gradient;
        text-transform: uppercase;
      }
    }
  }

  @media (max-width: 1000px) {
    &__content {
      width: 100%;
      font-size: 1.25em;

      &Entry {
        width: 100%;
      }
    }
  }
}
