@import '../../styles/variables';

.MyBookings {
  max-width: 50em;

  &__title {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 3em;
    display: flex;

    &Filter {
      margin-left: 1em;
      padding: 0.25em;
      border: 1px solid $light-gray;
      border-radius: 5px;
      width: 5em;
      display: flex;
      align-items: center;

      select {
        font-size: 0.875rem;
        letter-spacing: 1px;
        border: none;
        outline: none;
        flex-grow: 1;
        margin-left: 1em;
        padding: 0;
        font-family: Lato;
        background-color: white;
        color: $gray;
        font-weight: bold;
      }
    }
  }

  &__bookings {
    display: flex;
    flex-direction: column;
    align-items: center;

    &Placeholder {
      font-size: 1.25em;
      color: $gray;
      margin-bottom: 2em;
    }

    .pagination {
      display: flex;
      list-style: none;
      color: $dark-green;
      margin-bottom: 0;

      li {
        margin-right: 1em;
        cursor: pointer;

        &:last-child {
          margin-right: none;
        }

        &.active {
          text-decoration: underline;
        }

        &.disabled {
          color: $gray;
        }
      }
    }
  }

  &__entry {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: solid 1px $light-gray;

    &--past {
      .MyBookings__entryContentPhoto {
        img {
          filter: grayscale(1);
        }
      }
    }

    &:hover {
      background-color: rgba(126, 214, 97, 0.1);

      .MyBookings__entry {
        &Messages,
        &Actions {
          display: flex;
        }
      }
    }

    &Content {
      display: flex;
      height: 15em;
      width: 100%;
      letter-spacing: 0.5px;
      padding: 2em 1em;
      cursor: pointer;

      &:last-child {
        border-bottom: none;
      }

      &Canceled {
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(-50%) translateX(25%);
        background-color: $pastel-red;
        color: white;
        z-index: 2;
        padding: 0.25em 0.5em;
        border-radius: 5px;
        font-size: 0.75em;
      }

      &Photo {
        position: relative;
        width: 40%;
        height: 100%;
        margin-right: 2.5em;

        &Background {
          width: 100%;
          height: 100%;
          border-radius: 5px;
          object-fit: cover;
        }

        &CategoryIcon {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0.75em;
          width: 3em;
          height: 3em;
          position: absolute;
          top: 1em;
          left: 1em;
          border-radius: 50%;
          background-color: white;
          box-shadow: $shadow;

          img {
            width: 100%;
            height: auto;
          }
        }
      }

      &Infos {
        display: flex;
        flex-grow: 1;

        &Left {
          flex-grow: 1;
          margin-right: 2em;

          &Coach {
            color: $gray;
            font-size: 0.8em;
            font-weight: 700;
            margin-bottom: 0.5em;
          }

          &Name {
            font-size: 1.25em;
            margin-bottom: 0.25em;
          }

          &Order {
            color: #485b6d;
            font-size: 0.75em;
            font-weight: bold;
            font-style: italic;
            letter-spacing: 0.55px;
            margin-bottom: 1em;
          }

          &Location {
            display: flex;
            color: $gray;
            font-size: 0.8em;
            font-weight: 700;
            margin-bottom: 1em;

            svg {
              height: 1.25em;
              width: auto;
              margin-right: 0.5em;
            }
          }

          &Dates {
            display: flex;
            flex-direction: column;
            color: $gray;
            font-size: 0.8em;
            margin-bottom: 1em;

            &Entry {
              margin-bottom: 0.5em;

              &:last-child {
                margin-bottom: 0;
              }

              span {
                color: black;
                text-transform: uppercase;
                margin-right: 0.5em;
              }

              svg {
                height: 1em;
                width: auto;
                margin-right: 0.5em;
              }
            }
          }

          &MaxAttendees {
            display: flex;
            color: $gray;
            font-size: 0.8em;
            margin-bottom: 1em;

            svg {
              height: 1em;
              width: auto;
              margin-right: 0.5em;
            }
          }
        }

        &Right {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          &Rating {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $dark-green;
            background-color: white;
            border-radius: 5px;
            border: 1px solid $dark-green;
            padding: 0.5em 1em;
            font-weight: 700;

            svg {
              margin-left: 0.5em;
            }
          }

          &Price {
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            background-color: $dark-green;
            border-radius: 5px;
            padding: 0.5em 1em;
            font-weight: 700;
          }
        }
      }
    }

    &Messages {
      display: none;
      flex-direction: column;
      margin-bottom: 2em;
      font-size: 0.8em;
      color: $gray;
      text-align: center;

      &Entry {
        margin-bottom: 1em;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &Actions {
      display: none;
      margin-bottom: 2em;
      font-size: 0.8em;
    }

    &Preview,
    &Rate,
    &Invoice {
      .Button,
      &.Button {
        width: 20em;
        background-color: $dark-green;
        color: white;
        margin-right: 2em;

        svg {
          height: 2em;
        }
      }

      &Title {
        margin-left: 1em;
      }

      svg {
        path {
          fill: white;
        }
      }
    }

    &Message {
      width: 20em;
      background-color: $dark-green;
      color: white;
      margin-right: 2em;

      &Title {
        margin-left: 1em;
      }

      svg {
        path {
          fill: white;
        }
      }
    }

    &Cancel {
      width: 20em;
      background-color: $pastel-red;
      color: white;
      margin-right: 2em;

      &Title {
        margin-left: 1em;
      }

      svg {
        path {
          fill: white;
        }
      }
    }
  }

  @media (max-width: 1000px) {
    &__entry {
      &Content {
        flex-direction: column;
        height: auto;

        &Photo {
          height: 10em;
          width: 100%;
          margin-bottom: 2em;
        }
      }

      &Actions {
        padding: 0 1em;
        display: flex;

        .MyBookings__entryPreview {
          margin-right: 1em;
        }

        .MyBookings__entryCancel {
          margin-left: 1em;
        }

        .Button {
          margin-right: 0;
          width: unset;
        }
      }
    }
  }
}
