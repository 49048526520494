@import '../../styles/variables';

.Registrations {
  position: relative;
  max-width: 75em;

  &__loader {
    height: 100%;
    width: 100%;
    background-color: rgba(247, 247, 247, 0.8);
    position: fixed;
    z-index: 3;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
  }

  &__title {
    display: flex;
    align-items: baseline;
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 3em;
  }

  &__table {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.7em;
    letter-spacing: 0.9px;

    &Cell {
      &--centered {
        text-align: center;
      }
    }

    .pagination {
      display: flex;
      list-style: none;
      color: $dark-green;
      margin-bottom: 0;
      font-size: 1rem;

      li {
        margin-right: 1em;
        cursor: pointer;

        &:last-child {
          margin-right: none;
        }

        &.active {
          text-decoration: underline;
        }

        &.disabled {
          color: $gray;
        }
      }
    }

    &Sessions {
      &Entry {
        cursor: pointer;
      }

      &Registrations {
        margin-bottom: 2em;
      }
    }

    table {
      width: 100%;
      border-spacing: 0;
      border-collapse: collapse;
      margin-bottom: 1em;

      thead {
        background-color: #f1fbf0;
        height: 2em;

        th {
          padding: 1em;
          color: $gray;
        }
      }

      td {
        padding: 0.75rem;
        font-size: 0.875rem;

        & > div {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      tr {
        border-bottom: 1px solid #f1f2f7;

        &:last-child {
          border-bottom: 0;
        }
      }
    }

    &Name {
      display: flex;
      font-weight: bold;

      &Icon {
        border-radius: 50%;
        border: 1px solid $light-gray;
        padding: 0.4em;
        height: 3em;
        width: 3em;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1em;

        img {
          height: 1.25em;
        }
      }

      &Label {
        &Cancelled {
          margin-left: 2em;
          background-color: $pastel-red;
          color: white;
          padding: 0.25em 0.5em;
          border-radius: 5px;
          font-size: 0.75em;
        }
      }
    }

    &UserName {
      font-weight: bold;
      color: $dark-green;
    }

    &Date {
      &Time {
        display: none;
      }
    }

    &Id {
      font-size: 0.8em;
      color: $gray;
    }

    &Id,
    &Date,
    &Time {
      color: $gray;
    }

    &Actions {
      display: flex;
      justify-content: center;
    }

    &Action {
      display: flex;
      border-radius: 50%;
      height: 2.5em;
      width: 2.5em;
      padding: 0.625em;
      margin-right: 0.75em;
      cursor: pointer;
      background-color: $dark-green;
      position: relative;

      &:hover {
        box-shadow: $shadow;
      }

      &:last-child {
        margin-right: 0;
      }

      svg {
        width: 100%;
        height: auto;

        path {
          fill: white;
        }
      }

      &Badge {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(25%) translateY(-25%);
        box-shadow: $shadow;
        height: 1.75em;
        width: 1.75em;
        font-size: 0.75em;
        background-color: white;
        border-radius: 50%;
        padding: 0.25em;
      }
    }
  }

  @media (max-width: 1000px) {
    &__table {
      th,
      td {
        display: none;

        &.Registrations__tableCell--mobileVisible {
          display: table-cell;
          font-size: 0.8em;
        }
      }

      .FoldingRow {
        &__accordion {
          td {
            column-span: all;
          }
        }
      }

      &Name {
        &Icon {
          width: 2.5em;
          height: 2.5em;
          margin: auto;
          margin-bottom: 0.25em;
          border: none;

          img {
            width: 100%;
          }
        }

        &Label {
          text-align: center;
          display: flex;
          flex-direction: column;

          &Cancelled {
            margin-top: 0.75em;
            margin-left: 0;
          }
        }
      }

      &Date {
        &Time {
          display: block;
        }
      }

      &Actions {
        font-size: 0.75em;
      }
    }
  }
}
