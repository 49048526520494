@import '../../styles/variables';

.Multiselect {
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 0;
  transform: translateY(calc(100% + 1.5em));
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
  padding: 1em;

  &__search {
    display: flex;
    align-items: center;

    svg {
      margin-right: 1em;
    }

    input {
      &[type='text'] {
        border: none;
        outline: none;
      }

      &::placeholder {
        font-size: 0.75em;
        letter-spacing: 0.4px;
      }
    }
  }

  &__separator {
    height: 2px;
    width: 100%;
    background-color: $light-gray;
    margin: 1em 0;
  }

  &__options {
    max-height: 15em;
    overflow-y: auto;

    &Entry {
      display: flex;
      align-items: center;
      font-weight: normal;
      margin-bottom: 1em;
      cursor: pointer;

      &Checkbox {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1em;
        height: 1em;
        border-radius: 2px;
        border: 1px solid $light-gray;
        margin-right: 1em;
      }

      &--selected {
        color: $dark-green;

        .Multiselect__optionsEntryCheckbox {
          background-color: $dark-green;
          border: none;
        }
      }
    }
  }
}
