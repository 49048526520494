@import '../../styles/variables';

.FileInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  color: $dark-green;
  padding: 0.75em 1.5em;
  box-shadow: 0 2px 23px 0 $dark-green;
  background-color: white;
  border-radius: 5px;
  width: 100%;

  &--filled {
    border: 3px solid $dark-green;
  }

  &--filled.FileInput--pending {
    border-color: $pastel-yellow;
  }

  &--filled.FileInput--rejected {
    border-color: $pastel-red;
  }

  &__label {
    font-size: 0.8em;
    letter-spacing: 0.9px;
    margin-right: 0.5em;
  }

  &__left,
  &__right {
    display: flex;
  }

  svg {
    height: 100%;
    width: auto;

    path {
      fill: $dark-green;
    }
  }

  &__check {
    svg {
      height: 0.75em;
      width: auto;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.75em;
    width: 1.75em;
    padding: 0.25em;
    border: 1px solid $dark-green;
    border-radius: 3px;
    margin-right: 0.5em;
    cursor: pointer;

    &:hover {
      box-shadow: $shadow;
    }

    &:last-child {
      margin-right: 0;
    }

    &--preview {
      background-color: $dark-green;

      svg {
        path {
          fill: white;
        }
      }
    }

    &--upload {
      background-color: $light-green;
      border-color: $light-green;

      svg {
        path {
          fill: white;
        }
      }
    }
    &--delete {
      padding: 0.5em;
    }
  }

  input[type='file'] {
    display: none;
  }

  label {
    cursor: pointer;
  }
}
