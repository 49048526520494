@import '../../styles/variables';

.MyCoachingClasses {
  position: relative;
  max-width: 75em;

  &__add {
    display: none;
  }

  &__title {
    display: flex;
    align-items: baseline;
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 0.5em;

    &Filter {
      margin-left: 1em;
      padding: 0.25em;
      border: 1px solid $light-gray;
      border-radius: 5px;
      width: 5em;
      display: flex;
      align-items: center;

      select {
        font-size: 0.875rem;
        letter-spacing: 1px;
        border: none;
        outline: none;
        flex-grow: 1;
        margin-left: 1em;
        padding: 0;
        font-family: Lato;
        background-color: white;
        color: $gray;
        font-weight: bold;
      }
    }

    &Add {
      font-size: 0.5em;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      background-color: $dark-green;
      border-radius: 50px;
      padding: 0.5em 1em;
      cursor: pointer;
      margin-left: 2em;

      &:hover {
        box-shadow: $shadow;
      }

      svg {
        margin-right: 0.5em;
      }
    }
  }

  &__subtitle {
    width: 100%;
    font-size: 1.25em;
    color: $gray;
    margin-bottom: 3em;
  }

  table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    thead {
      background-color: #f1fbf0;
      height: 2em;

      th {
        padding: 1em;
        color: $gray;
        font-size: 0.875rem;
      }
    }

    td {
      padding: 0.75rem;
      font-size: 0.875rem;

      & > div {
        display: flex;
        align-items: center;
      }
    }

    tr {
      border-bottom: 1px solid #f1f2f7;

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  &__table {
    font-size: 0.8em;
    letter-spacing: 0.9px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .pagination {
      display: flex;
      list-style: none;
      color: $dark-green;
      margin-bottom: 0;

      li {
        margin-right: 1em;
        cursor: pointer;

        &:last-child {
          margin-right: none;
        }

        &.active {
          text-decoration: underline;
        }

        &.disabled {
          color: $gray;
        }
      }
    }

    &Name {
      font-weight: bold;
      justify-content: flex-start;

      &CategoryIcon {
        display: none;
      }
    }

    &Category {
      display: flex;
      align-items: center;

      img {
        width: 2em;
        margin-right: 1em;
      }
    }

    &Actions {
      justify-content: center;
    }

    &Action {
      border-radius: 50%;
      background-color: $dark-green;
      height: 2.5em;
      width: 2.5em;
      padding: 0.625em;
      margin-right: 0.75em;
      cursor: pointer;

      &:hover {
        box-shadow: $shadow;
      }

      &:last-child {
        margin-right: 0;
      }

      svg {
        height: 100%;
        width: auto;

        path {
          fill: white;
        }
      }

      &--disabled {
        background-color: $gray;
        cursor: initial;

        &:hover {
          box-shadow: none;
        }
      }
    }
  }

  @media (max-width: 1000px) {
    &__title {
      align-items: flex-end;

      &Add {
        height: 3em;
        width: 3em;
        padding: 0;
        align-items: center;
        justify-content: center;

        svg {
          height: 100%;
          margin-right: 0;
        }

        span {
          display: none;
        }
      }
    }

    &__table {
      th,
      td {
        display: none;

        &.MyCoachingClasses__tableCell--mobileVisible {
          display: table-cell;
        }
      }

      &Name {
        &CategoryIcon {
          display: block;
          width: 2em;
          margin-right: 1em;
        }
      }

      &Actions {
        font-size: 0.75em;
      }
    }
  }
}
