@import '../styles/variables';

.Layout__content > .CoachSignup {
  padding-top: 10em;
  min-height: 100vh;

  @media (max-width: 1000px) {
    padding-top: 7em;
  }
}

.CoachSignup {
  background-image: url('../../assets/images/background_login.png');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;

  .stepper {
    background-color: transparent;
    box-shadow: none;
    border: none;
    font-family: Lato;
    max-width: calc(17em * 2 + 2em);

    section:empty {
      display: none;
    }

    &__connector {
      color: white;
    }

    &__header {
      box-shadow: none;
      width: 70%;
      margin: auto;
    }

    &__head {
      padding: 0;
      z-index: 2;
      border-radius: 50%;
      height: fit-content;

      &:not(.stepper__head--disabled):hover {
        background-color: transparent;
      }

      &__index {
        color: $dark-green;
        background-color: white;
        box-shadow: 0 2px 5px 0 $dark-green;
        margin: 5px;

        svg path:last-child {
          fill: $dark-green;
        }
      }

      &--active {
        .stepper__head__index {
          background-color: $dark-green;
          color: white;
        }
      }
    }

    &__section {
      padding: 2em;
    }

    .RadioButton {
      a {
        text-decoration: underline;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    justify-content: center;
    flex-grow: 1;
  }

  &__title {
    display: flex;
    flex-direction: column;
    font-weight: 900;
    font-family: Montserrat;
    text-align: center;
    text-shadow: 0 2px 15px #18894c;
    color: white;
    margin-bottom: 5em;
    z-index: 2;

    &Top {
      font-size: 3.75em;
      letter-spacing: 0.8px;
    }

    &Bottom {
      font-size: 5.5em;
      letter-spacing: 1.25px;
      z-index: 2;
    }
  }

  &__womanPicture {
    height: 75%;
    position: absolute;
    top: 10%;
    left: 45%;
  }

  @media (max-width: 1000px) {
    padding: 0 5%;
    padding-top: 5em;
    padding-bottom: 5em;

    &__womanPicture {
      display: none;
    }

    &__title {
      margin-bottom: 1.5em;

      &Top,
      &Bottom {
        font-size: 3em;
      }
    }

    .stepper {
      width: 100%;

      &__section {
        padding: 0;
      }
    }
  }
}
