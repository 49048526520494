@import '../../styles/variables';

.MyPaymentMethods {
  max-width: 50em;

  &__title {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 3em;
  }

  &__content {
    display: flex;
    flex-direction: column;

    &Cards {
      margin-bottom: 2em;

      &Placeholder {
        font-size: 1.25em;
        color: $gray;
        margin-bottom: 2em;
        text-align: center;

        span {
          font-size: 0.65em;
        }
      }

      &Entry {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $light-gray;
        border-radius: 5px;
        padding: 1.25em 2em;

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          .MyPaymentMethods__contentCardsEntryRight {
            display: flex;
          }
        }

        &Left,
        &Right {
          display: flex;
          align-items: center;
        }

        &Right {
          display: none;
        }

        &Icon {
          margin-right: 2em;
        }

        &Last4 {
          margin-bottom: 0.5em;
        }

        &Expiration {
          font-size: 0.75em;
          color: $gray;
        }

        .Button {
          background-color: $pastel-red;
          color: white;

          &:hover {
            box-shadow: $shadow;
          }

          svg {
            margin-right: 1em;
            path {
              fill: white;
            }
          }
        }
      }
    }
  }
}
