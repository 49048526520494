@import '../../styles/variables';

.Footer {
  background-color: #f3fdf8;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #485b6d;
  padding: 5em 0 3em 0;
  font-size: 0.8em;
  z-index: 2;
  flex-shrink: 0;

  @media (max-width: 1500px) {
    font-size: 0.65em;
  }

  @media (max-width: 1250px) {
    font-size: 0.55em;
  }

  a {
    color: #485b6d;
    text-decoration: none;
  }

  &__section {
    margin-right: 10em;

    & > div {
      margin-bottom: 1em;

      &.Footer__title {
        margin-bottom: 2em;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__content {
    display: flex;
    margin-bottom: 3em;
  }

  &__logo {
    svg {
      width: 7em;
      height: auto;
    }
  }

  &__title {
    color: $dark-green;
    font-weight: 700;
    font-size: 1.25em;
  }

  &__coaches {
    width: 20em;

    &Content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      a {
        margin-bottom: 1em;

        &:nth-child(2n + 1) {
          margin-right: 2em;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__categories {
    width: 20em;

    &Content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      &Entry {
        margin-bottom: 1em;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__recruitment {
    margin-top: 2em;
    margin-bottom: 1em;
  }

  &__button {
    display: flex;
    align-items: center;
    border-radius: 5px;
    color: white;
    background: $gradient;
    padding: 0.75em 2em;
    font-weight: 900;
    cursor: pointer;

    a {
      color: white;
    }

    &:hover {
      box-shadow: $shadow;
    }

    svg {
      font-size: 1.5em;
      margin-left: 1em;
    }
  }

  &__highlight {
    color: $dark-green;
    font-weight: 700;

    a {
      color: $dark-green;
    }
  }

  &__socialNetworks {
    .Footer__title {
      text-align: center;
    }

    &Icons {
      a {
        margin-right: 2em;

        &:last-child {
          margin-right: 0;
        }

        svg,
        img {
          height: 34px;
          width: auto;
        }
      }
    }
  }

  &__contact {
    text-align: center;
    margin-right: 0;
    margin-bottom: 5em;
  }

  @media (max-width: 1000px) {
    &__content {
      font-size: 1.5em;
      flex-direction: column;
      align-items: center;
    }

    &__section {
      margin-right: 0;
      margin-bottom: 3em;
    }

    &__categories {
      &Content {
        &Entry {
          text-align: center;
        }
      }
    }

    &__logo {
      margin-bottom: 3em;
    }

    &__title {
      text-align: center;
    }
  }

  &__contact {
    & > div {
      width: 100%;
      text-align: center;
    }
  }

  &__links {
    & > div {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
