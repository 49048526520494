@import '../../styles/variables';

.ForgottenPassword {
  min-height: 100vh;
  background-image: url('../../../assets/images/background_login.png');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__title {
    display: flex;
    flex-direction: column;
    font-weight: 900;
    font-family: Montserrat;
    text-align: center;
    text-shadow: 0 2px 15px #18894c;
    color: white;
    margin-bottom: 5em;

    &Top {
      font-size: 3.75em;
      letter-spacing: 0.8px;
    }

    &Bottom {
      font-size: 5.5em;
      letter-spacing: 1.25px;
      z-index: 2;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    margin-bottom: 1em;

    &Top,
    &Middle {
      display: flex;
      margin-bottom: 1.5em;
    }

    .Input {
      margin-right: 1.5em;
      width: 20em;

      &:last-child {
        margin-right: 0;
      }
    }

    &__submit {
      width: 12.5em;
    }
  }
}
