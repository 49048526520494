$green: #7ed661;
$dark-green: #4dba7f;
$light-green: #7ee8ae;
$pastel-red: #fc7169;
$pastel-green: #4dce76;
$pastel-yellow: #fcda69;

$black: #000000;
$gray: #99a3ac;
$light-gray: #d2d2d2;

$gradient: linear-gradient(to right, #37db82, $dark-green);
$gradient-light: linear-gradient(to right, #afffd4, $dark-green);

$shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
