@import '../../../styles/variables';

.MultiselectFilter {
  position: relative;

  &--expanded {
    background-color: rgba(126, 214, 97, 0.1);
  }

  &--withSelection {
    .MultiselectFilter__title {
      color: black;
    }
  }

  &__title {
    display: flex;
    align-items: baseline;
    border-radius: 5px;

    &Badge {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $dark-green;
      color: white;
      height: 2em;
      width: 2em;
      font-size: 0.75em;
      line-height: 2em;
      border-radius: 50%;
      margin-left: 1em;
    }
  }
}
