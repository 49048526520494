@import '../styles/variables';

.CoachPreview {
  display: flex;
  min-height: fit-content;
  width: 100%;
  letter-spacing: 0.5px;
  padding: 2em 1em;
  border-bottom: solid 0.5px $light-gray;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: rgba(126, 214, 97, 0.1);
  }

  &__photo {
    position: relative;
    width: 40%;
    height: 11em;
    margin-right: 2.5em;
    flex-shrink: 0;

    &Background {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      object-fit: cover;
    }
  }

  &__infos {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;

    &Left {
      flex-grow: 1;
      margin-right: 2em;

      &Categories {
        color: $gray;
        font-size: 0.65em;
        font-weight: 700;
        margin-bottom: 0.5em;
      }

      &Name {
        font-size: 1.25em;
        margin-bottom: 1em;
      }

      &Location,
      &TaxDeduction {
        display: flex;
        color: $gray;
        font-size: 0.8em;
        font-weight: 700;
        margin-bottom: 1em;
        align-items: center;

        svg {
          width: 1.25em;
          height: auto;
          margin-right: 0.5em;

          path {
            fill: $dark-green;
          }
        }
      }
    }

    &Right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &Rating {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $dark-green;
        background-color: white;
        border-radius: 5px;
        border: 1px solid $dark-green;
        padding: 0.5em 1em;
        font-weight: 700;

        svg {
          margin-left: 0.5em;
        }
      }

      &Price {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        background-color: $dark-green;
        border-radius: 5px;
        padding: 0.5em 1em;
        font-weight: 700;
      }
    }
  }
}
