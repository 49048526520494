@import '../../styles/variables';

.RadioButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  &__radio {
    border-radius: 50%;
    border: 2px solid white;
    background-color: white;
    width: 1em;
    height: 1em;
    box-shadow: $shadow;

    &--checked {
      background-color: $dark-green;
    }
  }

  &__label {
    margin-left: 0.75em;
    color: $dark-green;
    font-weight: 700;
  }
}
