@import '../../styles/variables';

.CategoryTag {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid $light-gray;
  border-radius: 5px;
  font-size: 0.8em;
  font-weight: bold;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  overflow: hidden;
  color: #485b6d;

  &__content {
    display: flex;
    align-items: center;
    padding: 0.7em 1em;

    img {
      margin-right: 1em;
      height: 3em;
    }
  }

  &__banner {
    background-color: $light-green;
    color: white;
    font-size: 0.75em;
    width: 100%;
    text-align: center;
    padding: 0.3em 0.75em;
  }
}
