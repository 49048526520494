@import '../../../styles/variables';

.CoachRating {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100vw;
  background-color: rgba(247, 247, 247, 0.8);
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7em 25em;

  &__content {
    position: relative;
    background-color: white;
    max-width: 50em;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.26);
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    flex-direction: column;
    padding: 2em 5em;
    overflow-y: auto;

    &Header {
      $coach-image-width: 11em;
      font-size: 0.75em;
      position: relative;
      display: flex;
      align-items: center;
      padding: 2em 0 4em 0;
      border-bottom: 1px solid $light-gray;
      margin-bottom: 3em;

      &Images {
        position: relative;
        margin-right: 5em;

        &Coach {
          height: $coach-image-width;
          width: $coach-image-width;
          overflow: hidden;
          border-radius: 50%;
          border: 3px solid white;
          background-color: white;
          box-shadow: 0 5px 20px 0 rgba(76, 76, 76, 0.36);
        }

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      &Desc {
        display: flex;
        flex-direction: column;

        &Location {
          color: $gray;
          letter-spacing: 1px;
          text-transform: uppercase;
          font-size: 0.9em;
          font-weight: bold;
          margin-bottom: 1em;
        }

        &Name {
          font-size: 2.75em;
          font-weight: bold;
          margin-bottom: 0.5em;
        }

        &Rating {
          display: flex;
          align-items: center;
          color: $dark-green;
          font-size: 1.1em;
          margin-bottom: 0.5em;

          &Value {
            font-weight: 900;
            margin-right: 0.5em;
            letter-spacing: 1px;
          }

          .react-rater {
            font-size: 1.1em;
            letter-spacing: 4px;
            margin-right: 0.5em;
          }

          &Feedbacks {
            font-size: 0.75em;
            letter-spacing: 0.65px;
            font-weight: 500;
            text-decoration: underline;
            margin-right: 2em;

            a,
            a:visited {
              color: $dark-green;
            }
          }

          &Networks {
            a {
              margin-right: 0.75em;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }

        &Location {
          display: flex;
          font-weight: bold;
          color: $gray;
          align-items: center;
          margin-bottom: 2em;

          svg {
            height: 1em;
            width: auto;
            margin-right: 0.5em;
          }
        }

        &Book {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20em;
          height: 3em;
          color: white;
          font-family: Raleway;
          text-transform: uppercase;
          font-size: 0.9em;
          font-weight: bold;
          letter-spacing: 1.15px;
          text-align: center;
          background: $gradient;
          box-shadow: 0 2px 4px 0 rgba(74, 103, 136, 0.2);
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }

    &Body {
      display: flex;
      flex-direction: column;
      align-items: center;

      &Title {
        color: $gray;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: bold;
        text-align: center;
        margin-top: 0.5em;
        margin-bottom: 1.5em;
      }

      &Feedback {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        &Rating {
          font-size: 1.5em;
          margin-bottom: 0.75em;
        }

        &Message {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          min-height: 10em;
          margin-bottom: 1em;
          border: 1px solid $light-gray;
          border-radius: 5px;
          overflow: hidden;
          width: 100%;

          textarea {
            padding: 1em;
            width: 100%;
            flex-grow: 1;
            resize: vertical;
            border: none;
            outline: none;
            font-family: Lato;
          }
        }

        .Button {
          background: $gradient;
          color: white;
          width: 15em;

          &--disabled {
            cursor: initial;
            background: $light-gray;
          }
        }
      }
    }
  }

  @media (max-width: 1000px) {
    padding: 0;

    &__content {
      &Header {
        flex-direction: column;
        align-items: center;

        &Images {
          margin-right: 0;

          &Coach {
            margin-bottom: 1em;
          }
        }

        &Desc {
          align-items: center;
          text-align: center;
        }
      }
    }
  }
}
