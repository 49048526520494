@import '../../styles/variables';

.CoachingClassRegistrationPayment {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    color: $gray;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
    text-align: center;
    margin-top: 0.5em;
    margin-bottom: 3em;
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-bottom: 2em;
    width: 100%;
    overflow: visible;

    &NewCard {
      flex-grow: 1;
      flex-direction: column;
      align-items: center;

      &Wrapper {
        border: 1px solid $light-gray;
        border-radius: 5px;
        padding: 1rem;
        display: flex;
      }

      &HolderName {
        border: 1px solid $light-gray;
        border-radius: 5px;
        display: flex;
        margin-bottom: 1em;

        .Input {
          padding: 1rem;
          box-shadow: none;

          input {
            font-size: 1em;

            &::placeholder {
              font-size: 1em;
              letter-spacing: 0;
              font-weight: 400;
            }
          }
        }
      }

      &Title {
        color: $gray;
        font-size: 0.8em;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: bold;
        text-align: center;
        margin-top: 0.5em;
        margin-bottom: 1em;
      }

      .StripeElement {
        width: 100%;

        input {
          font-family: Lato;
        }
      }
    }

    .RadioButton {
      margin-top: 0.75em;
      width: 100%;

      &__label {
        color: $gray;
        font-size: 0.75em;
      }
    }

    &SavedCards {
      margin-bottom: 2em;

      &Title {
        color: $gray;
        font-size: 0.8em;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: bold;
        text-align: center;
        margin-top: 0.5em;
        margin-bottom: 1em;
      }

      &Entry {
        display: flex;
        align-items: center;
        border: 1px solid $light-gray;
        border-radius: 5px;
        padding: 1.25em 2em;
        margin-bottom: 1em;

        &:last-child {
          margin-bottom: 0;
        }

        &Last4 {
          margin-bottom: 0.5em;
        }

        .RadioButton {
          margin-top: 0;

          &__label {
            display: flex;
            align-items: center;

            svg {
              margin-right: 1em;
              height: 44px;
            }
          }
        }

        &Infos {
          display: flex;
          flex-direction: column;
        }
      }
    }

    &CGV {
      a {
        text-decoration: underline;
      }
    }
  }

  &__footer {
    width: 100%;

    &Total {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      font-size: 1.25em;
      color: $dark-green;
      text-transform: uppercase;
      margin-bottom: 2em;

      &Right {
        font-size: 1.25em;
      }
    }

    .Button {
      color: white;
      background: $gradient;
      text-transform: uppercase;

      &--disabled {
        background: $light-gray;
      }
    }
  }

  @media (max-width: 1000px) {
    font-size: 1.25em;

    &__content {
      &SavedCards {
        &Entry {
          &Infos {
            font-size: 1.125em;
          }
        }
      }
    }
  }
}
