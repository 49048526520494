@import '../../../styles/variables';

.CoachingClassForm {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100vw;
  background-color: rgba(247, 247, 247, 0.8);
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7em 25em;

  &__content {
    background-color: white;
    max-width: 50em;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.26);
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    flex-direction: column;
    padding: 1em;
    overflow-y: auto;

    &Close {
      align-self: flex-end;
      margin-bottom: 1em;
      cursor: pointer;

      svg {
        width: 1em;
        height: auto;

        path {
          fill: $gray;
        }
      }
    }

    &Title {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 2em;
      font-weight: bold;

      svg {
        margin-top: 0.5em;

        path,
        ellipse {
          fill: $dark-green;
        }
      }
    }

    &Form {
      display: flex;
      flex-direction: column;
      padding: 2em;

      &Row {
        display: flex;
        margin-bottom: 1.5em;
        align-items: flex-end;
        flex-shrink: 0;

        &--alignTop {
          align-items: flex-start;
        }

        &:last-child {
          padding-bottom: 2em;
        }

        .InputWithLabel {
          margin-right: 2em;
          flex-grow: 1;
          flex-basis: 0;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      &Address {
        display: block;
      }

      .Button {
        background-color: $dark-green;
        color: white;
        flex-grow: 1;
        margin-right: 2em;

        &:last-child {
          margin-right: 0;
        }

        &:first-child {
          border: 1px solid $dark-green;
          background-color: white;
          color: $dark-green;
        }

        &--disabled {
          color: white;
          background-color: $light-gray;
          cursor: initial;

          &:first-child {
            color: white;
            background-color: $light-gray;
            cursor: initial;
            border: none;
          }
        }
      }

      &Medias {
        &Wrapper {
          margin-bottom: 2em;
        }

        /* gap: 1em; emulation (remove when gap is supported) */
        --gap: 1em;
        display: inline-flex;
        flex-wrap: wrap;
        margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
        width: calc(100% + var(--gap));

        & > * {
          margin: var(--gap) 0 0 var(--gap);
        }
        /* gap: 1em; emulation (remove when gap is supported) */

        input[type='file'] {
          display: none;
        }

        &Item {
          width: 8em;
          height: 4.5em;
          position: relative;

          &:hover {
            .CoachingClassForm__contentFormMediasItemDelete {
              display: flex;
            }
          }

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }

          video {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }

          &Delete {
            display: none;
            position: absolute;
            background-color: $pastel-red;
            border-radius: 50%;
            padding: 0.25em;
            align-items: center;
            justify-content: center;
            right: 0;
            top: 0;
            transform: translateX(50%) translateY(-50%);
            cursor: pointer;

            svg {
              path {
                fill: white;
              }
            }
          }
        }

        &Add {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0.5em 1.5em;
          background-color: #f1fbf0;
          color: $dark-green;
          border-radius: 5px;
          width: fit-content;
          cursor: pointer;
          font-weight: bold;

          svg {
            margin-left: 1em;
            stroke-width: 0;

            path {
              fill: $dark-green;
            }
          }
        }
      }

      &Schedules {
        flex-grow: 1;
        flex-basis: 0;
        margin-right: 2em;

        &Title {
          margin-bottom: 1em;
          text-transform: uppercase;
          font-size: 0.75em;
          letter-spacing: 0.9px;
        }

        &Inputs {
          margin-bottom: 0.75em;

          &:last-child {
            margin-bottom: 0;
          }

          &Label {
            font-size: 0.75em;
            margin-bottom: 0.5em;
          }

          &Item {
            position: relative;
            margin-bottom: 0.5em;

            &:last-child {
              margin-bottom: 0;
            }

            &:hover {
              .CoachingClassForm__contentFormSchedulesInputsItemDelete {
                opacity: 1;
              }
            }

            &Canceled {
              position: absolute;
              top: 0;
              right: 0;
              transform: translateY(-50%) translateX(25%);
              background-color: $pastel-red;
              color: white;
              z-index: 2;
              padding: 0.25em 0.5em;
              border-radius: 5px;
              font-size: 0.75em;
            }

            &Type {
              display: flex;
              font-size: 0.75em;
              margin-top: 0.5em;
              margin-bottom: 1.5em;

              .RadioButton {
                margin-right: 1em;

                &:last-child {
                  margin-right: 0;
                }

                &__radio {
                  box-shadow: 0 2px 5px 0 $dark-green;
                }

                &__label {
                  margin-left: 0.5em;
                }
              }
            }

            &Delete {
              cursor: pointer;
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateX(calc(-50% - 1em)) translateY(-50%);

              svg {
                path {
                  fill: $pastel-red;
                }
              }
            }
          }

          .rw-datetime-picker {
            .rw-input {
              box-shadow: none;
              color: black;
              font-size: 0.875em;
              font-family: Lato;
              padding: 0.5rem 1rem;
            }

            .rw-i::before {
              color: $dark-green;
            }

            .rw-select {
              border-left: none;
            }

            .rw-btn {
              vertical-align: unset;
            }
          }
        }

        &Add {
          color: $dark-green;
          text-decoration: underline;
          font-size: 0.7em;
          cursor: pointer;
        }
      }

      &Levels {
        flex-grow: 1;
        flex-basis: 0;

        &Title {
          margin-bottom: 2em;
          text-transform: uppercase;
          font-size: 0.75em;
          letter-spacing: 0.9px;
        }

        &Sliders {
          &Entry {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1.5em;

            &Label {
              font-size: 0.75em;
              letter-spacing: 1.2px;
              flex-basis: 0;
              flex-grow: 1;

              &Percentage {
                font-size: 0.8em;
              }
            }

            &Slider {
              flex-grow: 3;
              flex-basis: 0;

              .input-range {
                &__track--active {
                  background-color: $dark-green;
                }

                &__slider {
                  background-color: $dark-green;
                  border: none;
                  height: 0.65rem;
                  width: 0.65rem;
                  margin-top: -0.49rem;
                }

                &__label {
                  display: none;
                }
              }
            }

            &:nth-child(n + 1) {
              .input-range {
                &__track--active {
                  background-color: #7ee8ae;
                }

                &__slider {
                  background-color: #7ee8ae;
                }
              }
            }

            &:nth-child(n + 2) {
              .input-range {
                &__track--active {
                  background-color: #ceffbe;
                }

                &__slider {
                  background-color: #ceffbe;
                }
              }
            }

            &:nth-child(n + 3) {
              .input-range {
                &__track--active {
                  background-color: #9befe5;
                }

                &__slider {
                  background-color: #9befe5;
                }
              }
            }

            &:nth-child(n + 4) {
              .input-range {
                &__track--active {
                  background-color: #7ed661;
                }

                &__slider {
                  background-color: #7ed661;
                }
              }
            }
          }
        }
      }
    }
  }

  &__photo {
    height: 7em;
    width: 7em;
    border-radius: 50%;
    border: 3px solid white;
    background-color: white;
    position: relative;
    margin: auto;
    margin-bottom: 3em;
    box-shadow: $shadow;

    input[type='file'] {
      display: none;
    }

    &Save {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 0;
      right: 0;
      height: 2em;
      width: 2em;
      background-color: white;
      border-radius: 50%;
      box-shadow: $shadow;
      cursor: pointer;

      svg {
        height: 1em;
        width: auto;

        path {
          fill: $dark-green;
        }
      }
    }

    &Upload {
      position: absolute;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 0.75em;
      font-weight: bold;
      text-shadow: $shadow;
      padding: 1em;
      height: 100%;
      border-radius: 50%;
      color: white;
      width: 100%;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.1);
      cursor: pointer;

      &:hover {
        opacity: 1;
        transition: opacity 0.1s;
      }
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  @media (max-width: 1000px) {
    padding: 0;

    &__photoUpload {
      opacity: 1;
    }

    &__content {
      &Form {
        &Row {
          margin-bottom: 0;
          flex-direction: column;

          .InputWithLabel {
            width: 100%;
            max-width: unset;
            margin-right: 0;
            margin-bottom: 2em;
            flex-basis: unset;
          }

          &:last-child {
            flex-direction: row;
          }
        }

        &Schedules {
          margin-bottom: 2em;
          margin-right: 0;
          width: 100%;

          &Add {
            font-size: 1em;
          }
        }

        &Levels {
          width: 100%;
          margin-bottom: 2em;
        }

        &Medias {
          &Item {
            &Delete {
              display: flex;
            }
          }
        }
      }
    }
  }
}
