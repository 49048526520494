@import '../styles/variables';

.MyAccount {
  display: flex;

  @media (max-width: 1400px) {
    font-size: 0.9em;
  }

  @media (max-width: 1400px) {
    font-size: 0.75em;
  }

  &__menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(126, 214, 97, 0.1);
    width: 30%;
    max-width: 25em;

    &Wrapper {
      padding: 2em;
    }

    &Top {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &Photo {
      height: 7em;
      width: 7em;
      border-radius: 50%;
      border: 3px solid white;
      background-color: white;
      margin-bottom: 1em;
      position: relative;
      display: flex;
      flex-direction: column;

      input[type='file'] {
        display: none;
      }

      &Save {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        right: 0;
        height: 2em;
        width: 2em;
        background-color: white;
        border-radius: 50%;
        box-shadow: $shadow;
        cursor: pointer;

        svg {
          height: 1em;
          width: auto;

          path {
            fill: $dark-green;
          }
        }
      }

      &Upload {
        position: absolute;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 0.75em;
        font-weight: bold;
        text-shadow: $shadow;
        padding: 1em;
        height: 100%;
        border-radius: 50%;
        color: white;
        width: 100%;
        opacity: 0;
        background-color: rgba(0, 0, 0, 0.1);
        cursor: pointer;

        &:hover {
          opacity: 1;
          transition: opacity 0.1s;
        }
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    &Name {
      font-size: 1.125em;
      letter-spacing: 0.5px;
      margin-bottom: 0.25em;
    }

    &Email {
      font-size: 0.875em;
      letter-spacing: 0.4px;
      font-weight: bold;
      color: $gray;
    }

    &Separator {
      background: white;
      height: 3px;
      width: 100%;
      margin: 3em 0;
    }

    &Entries {
      width: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em 5em;
    flex-grow: 1;
    overflow-y: auto;

    & > div {
      width: 100%;
    }
  }

  &__disconnect.MenuEntry {
    color: $pastel-red;
    justify-content: center;

    &:hover {
      color: $pastel-red;
    }
  }

  @media (max-width: 1000px) {
    position: relative;

    &__menu {
      display: none;
      width: 100%;
      position: fixed;
      max-width: unset;
      z-index: 3;
      background-color: rgba(242, 248, 239, 1);

      &Wrapper {
        height: 100%;
        width: 100%;
        overflow-y: auto;
      }

      &--visible {
        display: flex;
      }

      &PhotoUpload {
        opacity: 1;
      }
    }

    &__content {
      padding: 2em 5%;

      & > div {
        height: 100%;
        overflow: auto;
      }

      &--hidden {
        display: none;
      }
    }
  }
}
