@import '../../styles/variables';

.Header {
  z-index: 4;
  height: 5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 5em;
  font-size: 0.8em;
  font-weight: 900;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: white;

  &__hamburgerMenu {
    display: none;
  }

  &__left {
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 5em;
  }

  a {
    font-size: 1em;
    color: white;
    text-decoration: none;
    text-shadow: 0 2px 10px #4dba7f;
    letter-spacing: 0.5px;
  }

  &__logo {
    height: 2em;
    width: auto;
    margin-right: 4em;
    cursor: pointer;

    svg {
      height: 100%;
      width: auto;
    }
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &__entry {
    cursor: pointer;
    margin-right: 5em;
    position: relative;

    &:last-child {
      margin-right: 0;
    }
  }

  &__signin {
    background-color: white;
    color: $dark-green;
    border-radius: 4px;
    padding: 0.75em 2em;

    &--visibleMobile {
      display: none;
    }

    a {
      color: $dark-green;
      text-shadow: none;
    }
  }

  &__search {
    display: flex;
    align-items: center;

    @media (max-width: 1300px) {
      max-width: 30em;
    }

    @media (max-width: 1100px) {
      max-width: 25em;
    }

    &Inputs {
      display: flex;
      padding: 1em;
      border-radius: 5px;
      align-items: center;
      border: 1px solid $light-gray;
      height: 3em;
      margin-right: 1em;
    }

    .InputWithLabel {
      border: none;
      outline: none;
      width: 100%;
      text-overflow: ellipsis;

      &__input {
        border: none;
      }
    }

    svg {
      height: 1.25em;
      width: auto;
      margin-right: 1em;
    }

    input {
      border: none;
      outline: none;
      text-overflow: ellipsis;
    }

    &Left {
      display: flex;
      align-items: center;
      flex-grow: 1;

      div:last-child {
        flex-grow: 1;
      }
    }

    &Right {
      display: flex;
      align-items: center;
      flex-grow: 1;
    }

    &Button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $dark-green;
      border-radius: 5px;
      padding: 0.25em;
      color: white;
      display: flex;
      height: 3em;
      width: 3em;
      cursor: pointer;
      flex-shrink: 0;

      i {
        font-size: 1.5em;
      }
    }

    &Separator {
      height: 150%;
      width: 1px;
      background-color: $light-gray;
      margin: 0 1em;
    }
  }

  &__myAccount {
    width: 2.5em;
    height: 2.5em;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);

    svg {
      height: 100%;
      width: auto;
    }

    &--mobileVisible {
      display: none;
    }
  }

  &--withBackground {
    border-bottom: 1px solid $light-gray;
    background-color: white;

    a {
      font-size: 1em;
      color: $dark-green;
      text-decoration: none;
      letter-spacing: 0.5px;
      text-shadow: none;
    }

    .Header__signin {
      background: $gradient;
      border-radius: 4px;
      padding: 0.75em 2em;
      letter-spacing: 0.5px;

      a {
        color: white;
      }
    }

    .Header__myAccount {
      box-shadow: none;
    }
  }

  &--scrolled {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.31);

    &.Header--withBackground {
      border-bottom: none;
    }
  }

  &__categories {
    position: absolute;
    display: flex;
    left: 0;
    top: 4em;
    transform: translateX(-50%);
    background-color: white;
    padding: 0em 1em 2em 1em;
    border-radius: 5px;
    box-shadow: $shadow;
    padding-bottom: 1em;

    /* gap: 1em; emulation (remove when gap is supported) */
    --gap: 0.75em;
    display: inline-flex;
    flex-wrap: wrap;
    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
    width: calc(28em + var(--gap));

    & > * {
      margin: var(--gap) 0 0 var(--gap);
    }
    /* gap: 1em; emulation (remove when gap is supported) */

    &Entry {
      color: #4b4b4b;
      font-weight: normal;
      width: 42%;
      display: flex;
      align-items: center;
      position: relative;
      border-radius: 5px;
      padding: 0.5em 1em;

      &:hover {
        font-weight: bold;
        color: #525d75;
        background-color: #f2faef;

        .Header__categoriesEntrySubcategories {
          display: flex;
        }
      }

      img {
        margin-right: 1.5em;
        width: 2em;
      }

      &Subcategories {
        border-radius: 5px;
        padding: 1.5em;
        padding-bottom: 0;
        position: absolute;
        z-index: 2;
        bottom: 1px;
        left: 0;
        transform: translateY(100%);
        background-color: #f2faef;
        color: #525d75;
        display: none;
        width: calc(200% + 1em);
        flex-wrap: wrap;
        font-weight: normal;

        &Entry {
          width: 50%;
          margin-bottom: 1.5em;

          &:hover {
            font-weight: bold;
          }
        }
      }
    }
  }

  @media (max-width: 1000px) {
    padding: 0.5em 1em;

    &__search {
      display: none;
      margin-bottom: 1.5em;
      width: 100%;

      &--mobile {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      &Inputs {
        flex-direction: column;
        flex-shrink: 0;
        height: auto;
        width: 100%;
        margin-right: 0;
      }

      &Left {
        margin-right: 0;
        flex-shrink: 0;
        width: 100%;

        & > div {
          width: 100%;
        }

        svg {
          height: 2em;
          margin-right: 0.5em;
        }
      }

      &Separator {
        height: 1px;
        width: 100%;
        margin: 1em 0;
      }

      &Right {
        flex-shrink: 0;
        width: 100%;

        svg {
          height: 2em;
          margin-right: 0.5em;
        }
      }

      .InputWithLabel__input {
        padding: 0.25 0.25rem;
        width: 100%;

        select {
          padding: 0.25 0.25rem;
        }
      }

      &Button {
        background: $gradient;
        width: 100%;
        margin-top: 1em;
      }
    }

    &__logo {
      margin-right: 0;
    }

    &__right {
      .Header__entry {
        display: none;
      }
    }

    &__left {
      margin-right: 0;
    }

    &__entry {
      display: flex;
      justify-content: center;
      color: $dark-green;
      width: 100%;
      padding: 1em;
      font-size: 1.5em;
      margin-right: 0;
      border-bottom: 1px solid $light-gray;
      font-weight: bold;

      span {
        margin-bottom: 1em;

        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        color: $dark-green;
        text-shadow: none;
      }
    }

    &__signin {
      margin-top: 2em;
      background-color: $dark-green;
      color: white;
      border-radius: 4px;
      padding: 0.75em 2em;
      border-bottom: none;

      a {
        color: white;
      }
    }

    &__categories {
      position: initial;
      top: unset;
      left: unset;
      transform: unset;
      background-color: white;
      margin: unset;
      box-shadow: none;
      display: grid;
      font-size: 0.7em;
      grid-template-columns: repeat(2, 1fr);
      padding: 0;
      --gap: 0;

      &Entry {
        width: 100%;

        &:hover {
          font-weight: normal;
        }
      }

      &Button {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      img {
        width: 1.5em;
      }
    }

    &__myAccount {
      &--mobileVisible {
        display: flex;
      }
    }

    &__hamburgerMenu {
      display: block;
      height: 1.5em;
      width: 1.5em;
      cursor: pointer;

      & > svg {
        height: 100%;
      }

      &Content {
        &Wrapper {
          position: fixed;
          height: calc(100vh - 5em);
          width: 100vw;
          z-index: 2;
          background-color: white;
          top: 5rem;
          left: 0;
          overflow: scroll;
        }

        display: flex;
        flex-direction: column;
        padding: 1em;
        padding-bottom: 10em;
        align-items: center;
      }
    }
  }
}
