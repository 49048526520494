@import '../styles/variables';

.FAQ {
  padding: 2em 25%;
  color: #485b6d;
  background-color: #f3fdf8;

  &__title {
    font-size: 2em;
    color: $light-green;
    text-align: center;
    margin-bottom: 2em;
  }

  &__entry {
    margin-bottom: 2.5em;

    &Question {
      color: $light-green;
      font-size: 1.25em;
    }
  }
}

.Layout__content > .CGV {
  padding-top: 7em;
}
