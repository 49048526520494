@import '../../styles/variables';

.CoachingClassPreviewVertical {
  display: flex;
  flex-direction: column;
  height: 23em;
  width: 15em;
  border-radius: 5px;
  overflow: hidden;
  flex-shrink: 0;
  border: 0.5px solid $light-gray;
  background-color: white;

  &:hover {
    box-shadow: 0 2px 20px 0 rgba(131, 131, 131, 0.37);
  }

  &__top {
    position: relative;
    height: 35%;
    flex-shrink: 0;
    background-image: url('../../../assets/images/placeholder_carousel.jpg');
    background-size: cover;

    &Content {
      display: flex;
      justify-content: space-between;
      padding: 1em;
    }
  }

  &__bottom {
    padding: 1em 1.75em;
  }

  &__price {
    display: flex;
    align-items: center;
    background-color: $dark-green;
    color: white;
    border-radius: 5px;
    padding: 0.5em 1em;
    font-size: 0.75em;
    height: fit-content;

    &Content {
      span {
        font-weight: 700;
        font-size: 16px;
      }
    }
  }

  &__rating {
    display: flex;
    align-items: center;
    color: $dark-green;
    background-color: white;
    border-radius: 5px;
    border: 1px solid $dark-green;
    padding: 0.5em 1em;
    font-size: 0.8em;
    font-weight: 900;
    height: fit-content;

    svg {
      margin-left: 0.5em;
    }
  }

  &__category {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    right: 1em;
    transform: translateY(50%);
    border-radius: 50%;
    height: 2.5em;
    width: 2.5em;
    background-color: white;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 0.5em;

    img {
      width: 90%;
      height: 90%;
    }
  }

  &__coach {
    color: $gray;
    font-size: 0.7em;
    font-weight: 700;
    margin-bottom: 0.25em;
  }

  &__name {
    font-size: 1.25em;
    margin-bottom: 1em;
  }

  &__location {
    display: flex;
    color: $gray;
    font-size: 0.7em;
    font-weight: 700;
    margin-bottom: 1em;

    svg {
      height: 1.25em;
      margin-right: 0.5em;
    }
  }

  &__dates {
    display: flex;
    flex-direction: column;
    color: $gray;
    font-size: 0.7em;
    margin-bottom: 1em;
    font-size: 0.6em;

    &Entry {
      display: flex;
      align-items: center;
      margin-bottom: 0.5em;

      &:last-child {
        margin-bottom: 0;
      }

      &Value {
        overflow: hidden;
        white-space: nowrap;
        flex-shrink: 0;
      }

      span {
        color: black;
        text-transform: uppercase;
        margin-right: 0.25em;
      }

      svg {
        height: 1em;
        width: auto;
        margin-right: 0.5em;
      }
    }
  }

  &__maxAttendees {
    display: flex;
    color: $gray;
    font-size: 0.7em;
    margin-bottom: 1em;

    svg {
      height: 1em;
      width: auto;
      margin-right: 0.5em;
    }
  }
}
