@import '../../styles/variables';

.MenuEntry {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1.5em;
  color: $gray;
  letter-spacing: 0.45px;
  width: 100%;
  cursor: pointer;
  white-space: nowrap;

  &--focused,
  &:hover {
    color: $dark-green;
    font-weight: bold;
    background-color: white;
    border-radius: 5px;

    svg path,
    svg ellipse {
      fill: $dark-green;
    }
  }

  svg {
    width: 23px;
    height: 23px;
    margin-right: 2em;

    path,
    ellipse {
      fill: $gray;
    }
  }

  &--negative {
    svg {
      width: 23px;
      height: 23px;
      margin-right: 2em;

      path {
        fill: white;
      }

      rect {
        fill: $gray;
      }
    }

    &:hover {
      svg {
        path {
          fill: white;
        }

        rect {
          fill: $dark-green;
        }
      }
    }
  }

  &__text {
    position: relative;
  }

  &--validated {
    .MenuEntry__iconRight {
      svg {
        path {
          fill: $dark-green;
        }
      }
    }
  }

  &--notValidated {
    .MenuEntry__iconRight {
      svg {
        path {
          fill: $pastel-red;
        }
      }
    }
  }

  &__iconRight {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(100%) translateY(-50%);

    svg {
      height: 0.75em;
      margin: 0;
    }
  }
}
