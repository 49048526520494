@import '../../styles/variables';

.CoachSignupBusiness {
  &__title {
    font-weight: 900;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $dark-green;
    margin-bottom: 2em;
    text-align: center;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    margin-bottom: 1em;

    &Photo {
      border: 5px solid #c7e8d5;
      border-radius: 50%;
      margin-bottom: 2em;
      cursor: pointer;

      label {
        cursor: pointer;
      }

      input[type='file'] {
        display: none;
      }

      &Wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: $dark-green;
        background-color: white;
        border-radius: 50%;
        width: 9em;
        height: 9em;
        text-transform: uppercase;
        font-size: 0.75em;
        letter-spacing: 1px;
        box-shadow: $shadow;
        overflow: hidden;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        svg {
          margin-bottom: 0.5em;

          path,
          ellipse {
            fill: $light-green;
          }
        }
      }
    }

    &Row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 1em;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .Input {
      margin-right: 1.5em;

      &:last-child {
        margin-right: 0;
      }

      input::placeholder {
        text-transform: uppercase;
      }
    }

    .FileInput {
      margin-right: 1.5em;
      &:last-child {
        margin-right: 0;
      }
    }

    .Input,
    .FileInput {
      width: 23em;
    }

    .Button {
      width: 12.5em;
    }

    &Files {
      margin-bottom: 2em;
    }
  }

  &__actions {
    display: flex;

    .Button {
      margin-right: 2em;
      width: 17.5em;

      &:first-child {
        background-color: $dark-green;
        color: white;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media (max-width: 1000px) {
    padding: 0 5%;

    &__form {
      flex-direction: column;
      align-items: center;

      &Row {
        flex-direction: column;
        margin-bottom: 0;

        .FileInput {
          margin-right: 0;
          margin-bottom: 1em;
          width: 100%;
        }
      }
    }

    &__actions {
      margin-top: 1em;
      width: 100%;

      .Button {
        width: unset;
        padding: 1em 2em;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
    }
  }
}
