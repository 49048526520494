@import '../../styles/variables';

$react-rater-link: $light-gray; // color of star not rated
$react-rater-active: $dark-green;

.CoachingClassDetails {
  display: flex;
  flex-direction: column;

  @media (max-width: 1600px) {
    font-size: 0.9em;
  }

  @media (max-width: 1400px) {
    font-size: 0.75em;
  }

  @media (max-width: 1200px) {
    font-size: 0.6em;
  }

  &__header {
    $coach-image-width: 11em;
    position: relative;

    display: flex;
    align-items: center;
    padding: 2em 0;

    &Images {
      position: relative;
      height: 18em;
      width: 40%;
      margin-right: $coach-image-width / 2 + 4em;

      &Class {
        height: 100%;
        overflow: hidden;
        border-radius: 0 10em 10em 0;
        opacity: 0.75;
      }

      &Coach {
        height: $coach-image-width;
        width: $coach-image-width;
        overflow: hidden;
        border-radius: 50%;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateX(50%) translateY(-50%);
        border: 3px solid white;
        background-color: white;
        box-shadow: 0 5px 20px 0 rgba(76, 76, 76, 0.36);

        &--mobileVisible {
          display: none;
        }
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    &Desc {
      display: flex;
      flex-direction: column;

      &Coach {
        color: $gray;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-size: 0.9em;
        font-weight: bold;
        margin-bottom: 1em;
      }

      &Title {
        font-size: 2.75em;
        margin-bottom: 0.5em;
      }

      &Rating {
        display: flex;
        align-items: center;
        color: $dark-green;
        font-size: 1.1em;
        margin-bottom: 0.5em;

        &Value {
          font-weight: 900;
          margin-right: 0.5em;
          letter-spacing: 1px;
        }

        .react-rater {
          font-size: 1.1em;
          letter-spacing: 4px;
          margin-right: 0.5em;
        }

        &Feedbacks {
          font-size: 0.75em;
          letter-spacing: 0.65px;
          font-weight: 500;
          text-decoration: underline;
          margin-right: 2em;

          a,
          a:visited {
            color: $dark-green;
          }
        }

        &Networks {
          display: flex;
          position: relative;
          align-items: center;

          &Title {
            font-size: 1em;
            text-align: center;
            margin-left: 0.75em;

            @media (max-width: 1200px) {
              display: none;
            }
          }

          a {
            margin-right: 0.75em;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      &Location {
        display: flex;
        font-weight: bold;
        color: $gray;
        align-items: center;
        margin-bottom: 2em;

        svg {
          height: 1em;
          width: auto;
          margin-right: 0.5em;
        }
      }

      &Book {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20em;
        height: 3em;
        color: white;
        font-family: Raleway;
        text-transform: uppercase;
        font-size: 0.9em;
        font-weight: bold;
        letter-spacing: 1.15px;
        text-align: center;
        background: $gradient;
        box-shadow: 0 2px 4px 0 rgba(74, 103, 136, 0.2);
        border-radius: 4px;
        cursor: pointer;
      }
    }

    &Price {
      width: 20%;

      &Content {
        position: absolute;
        right: 0;
        top: 3em;
        background-color: $dark-green;
        color: white;
        padding: 1em 1.5em;
        border-radius: 5px 0 0 5px;
        max-width: 12em;
        width: 10%;

        @media (max-width: 1600px) {
          font-size: 0.9em;
          width: auto;
        }

        span {
          font-size: 2em;
          font-weight: bold;
          letter-spacing: 0;
        }
      }
    }
  }

  &__content {
    background-color: #f7f7f7;
    flex-grow: 1;
    padding: 3em 15em;
    display: flex;
    flex-direction: column;

    &Main {
      display: flex;

      &Left {
        display: flex;
        flex-direction: column;
        width: calc(100% * 2 / 3 - 2em);
        margin-right: 2em;

        &FirstLine {
          display: flex;
          margin-bottom: 2em;
        }

        &Desc {
          padding: 4em 5em;
          background-color: white;
          line-height: 2em;
          border-radius: 5px;
          margin-bottom: 2em;

          &Title {
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 1.5px;
            margin-bottom: 2em;
            text-align: center;
          }

          &Content {
            letter-spacing: 0.5px;
            color: #485b6d;
          }
        }

        &Medias {
          border-radius: 5px;
          overflow: hidden;
          position: relative;
          margin-bottom: 2em;

          &--mobile {
            display: none;
          }

          &Photo {
            overflow: hidden;
            border-radius: 10px;
            width: 100%;

            img,
            video {
              height: 30em;
              width: 100%;
              object-fit: cover;
              overflow: hidden;
            }
          }
        }
      }

      &Right {
        display: flex;
        flex-direction: column;
        width: calc(100% * 1 / 3);

        &Hours {
          display: flex;
          flex-direction: column;
          background-color: white;
          border-radius: 5px;
          padding: 1.5em 2em;
          margin-bottom: 2em;

          &Title {
            text-transform: uppercase;
            letter-spacing: 1.5px;
            text-align: center;
            font-weight: bold;
            margin-bottom: 2em;
          }

          &Content {
            margin-bottom: 1em;

            &Entry {
              display: flex;
              align-items: center;
              margin-bottom: 1em;
              text-transform: uppercase;
              font-size: 0.9em;

              &:last-child {
                margin-bottom: 0;
              }

              svg {
                margin-right: 0.5em;
              }

              span {
                color: #767676;
                margin-left: 1em;
                letter-spacing: 1.2px;
              }
            }
          }

          &SeeMore {
            color: $dark-green;
            cursor: pointer;
            font-weight: 900;
            text-align: center;
            font-size: 0.8em;
          }
        }

        &OtherClasses {
          display: flex;
          flex-direction: column;
          background-color: white;
          border-radius: 5px;
          padding: 1.5em 2em;
          margin-bottom: 2em;

          &Title {
            text-transform: uppercase;
            letter-spacing: 1.5px;
            text-align: center;
            font-weight: bold;
            margin-bottom: 2em;
          }

          &Content {
            display: flex;
            flex-wrap: wrap;

            /* gap: 1em; emulation (remove when gap is supported) */
            --gap: 1em;
            display: inline-flex;
            flex-wrap: wrap;
            margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
            width: calc(100% + var(--gap));

            & > * {
              margin: var(--gap) 0 0 var(--gap);
            }
            /* gap: 1em; emulation (remove when gap is supported) */

            a {
              display: flex;
            }

            &Entry {
              display: flex;
              align-items: center;
              border: 1px solid $light-gray;
              border-radius: 5px;
              padding: 0.75em 1em;
              font-size: 0.9em;
              font-weight: bold;
              letter-spacing: 1.2px;
              text-transform: uppercase;
              color: #485b6d;

              img {
                height: 3em;
                margin-right: 1em;
              }
            }
          }
        }

        &Levels {
          display: flex;
          flex-direction: column;
          background-color: white;
          border-radius: 5px;
          padding: 1.5em 2em;
          margin-bottom: 2em;

          &Title {
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 1.5px;
            margin-bottom: 2em;
          }

          &Entry {
            margin-bottom: 1em;

            &Label {
              color: #b3b9c2;
              font-weight: bold;
              margin-bottom: 0.5em;
            }

            &Value {
              width: 100%;
              height: 1em;
              background-color: #f8f8f8;
              border-radius: 3px;
              overflow: hidden;

              &Fill {
                height: 100%;
                background-color: $dark-green;
              }
            }

            &:nth-child(n + 1) {
              .CoachingClassDetails__contentMainRightLevelsEntryValueFill {
                background-color: #7ee8ae;
              }
            }

            &:nth-child(n + 2) {
              .CoachingClassDetails__contentMainRightLevelsEntryValueFill {
                background-color: #ceffbe;
              }
            }

            &:nth-child(n + 3) {
              .CoachingClassDetails__contentMainRightLevelsEntryValueFill {
                background-color: #9befe5;
              }
            }

            &:nth-child(n + 4) {
              .CoachingClassDetails__contentMainRightLevelsEntryValueFill {
                background-color: #7ed661;
              }
            }
          }
        }

        &Price {
          display: flex;
          flex-direction: column;
          background-color: white;
          border-radius: 5px;
          padding: 1.5em 2em;
          text-align: center;

          &Title {
            text-transform: uppercase;
            letter-spacing: 1.5px;
            text-align: center;
            font-weight: bold;
            margin-bottom: 2em;
          }

          &Content {
            font-weight: bold;
            margin-bottom: 1em;
          }

          &Notice {
            font-weight: 0.8em;
            color: #767676;
          }
        }
      }
    }

    &Reviews {
      &Content {
        background-color: white;
        border-radius: 5px;
        padding: 4em 5em;
      }

      &Title {
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 1.5px;
        margin-bottom: 2em;
      }

      &Sumup {
        display: flex;
        align-items: baseline;
        color: $dark-green;
        margin-bottom: 3.5em;

        &Average {
          display: flex;
          align-items: center;
          font-size: 1.5em;
          font-weight: 900;
          margin-right: 1em;
          letter-spacing: 1.3px;

          span {
            margin-right: 0.5em;
          }
        }

        &Count {
          text-decoration: underline;
          letter-spacing: 0.9px;
        }
      }

      &Entries {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &Entry {
        display: flex;
        align-items: center;
        color: #485b6d;
        margin-bottom: 3em;
        width: 100%;

        &:last-child {
          margin-bottom: 0;
        }

        &Left {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 2em;
          width: 12.5em;

          &Photo {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 2.5em;
            width: 2.5em;
            border-radius: 50%;
            overflow: hidden;
            margin-bottom: 0.75em;

            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }

          &Name {
            letter-spacing: 0.8px;
            margin-bottom: 0.25em;
          }
        }

        &Right {
          font-size: 0.875em;
          font-style: italic;
        }
      }

      .pagination {
        display: flex;
        list-style: none;
        color: $dark-green;
        margin-bottom: 0;

        li {
          margin-right: 1em;
          cursor: pointer;

          &:last-child {
            margin-right: none;
          }

          &.active {
            text-decoration: underline;
          }

          &.disabled {
            color: $gray;
          }
        }
      }
    }
  }

  &__infoCard {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5em 2em;
    border-radius: 5px;
    flex-grow: 1;
    margin-right: 2em;
    height: fit-content;

    &:last-child {
      margin-right: 0;
    }

    span {
      color: $gray;
      font-weight: bold;
      margin-right: 1em;
    }

    svg {
      margin-right: 1.5em;

      @media (max-width: 1600px) {
        width: 1.5em;
        height: auto;
      }
    }
  }

  @media (max-width: 1000px) {
    &__header {
      flex-direction: column;
      align-items: flex-start;
      padding: 2em 5%;
      font-size: 1.25em;

      &Images {
        width: auto;
        height: auto;
        margin: 0;
        margin-bottom: 2em;
        width: 100%;
        display: flex;
        justify-content: center;

        &Class {
          display: none;
        }

        &Coach {
          position: initial;
          left: unset;
          top: unset;
          transform: unset;
          display: none;

          &--mobileVisible {
            display: flex;
          }
        }
      }
    }

    &__content {
      padding: 2em;

      &Main {
        flex-direction: column;

        &Left {
          width: 100%;

          &Medias {
            display: none;

            &--mobile {
              display: block;
              margin-bottom: 2em;
            }
          }
        }

        &Right {
          width: 100%;

          &Hours {
            width: 100%;
          }

          &OtherClasses {
            justify-content: center;
          }
        }
      }

      &Reviews {
        padding: 0;

        &Entry {
          flex-direction: column;
          margin-bottom: 3em;

          &Left {
            font-size: 1.25em;
            margin-right: 0;
            margin-bottom: 1em;
          }

          &Right {
            font-size: 1.25em;
          }
        }
      }
    }

    &__infoCard {
      &Text {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}

@import '../../../node_modules/react-rater/lib/react-rater.scss';
