@import '../../styles/variables';

.FiltersHandler {
  padding: 1em 1.5em;
  letter-spacing: 1px;
  font-weight: bold;
  color: $gray;
  font-size: 0.75em;
  display: flex;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  width: intrinsic; /* Safari/WebKit uses a non-standard name */
  width: -moz-max-content; /* Firefox/Gecko */
  width: -webkit-max-content; /* Chrome */
  max-width: 100%;

  &--closed {
    cursor: pointer;
  }

  &__closed {
    display: flex;
  }

  &__logo {
    margin-right: 1em;

    cursor: pointer;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &Entry {
      padding: 0.5em 3em;
      margin: 0 0.75em;
      cursor: pointer;
      flex-shrink: 0;
    }
  }

  &__submitButton {
    display: none;
  }

  @media (max-width: 1000px) {
    &:not(.FiltersHandler--closed) {
      position: fixed;
      z-index: 3;
      background: white;
      top: 5rem;
      left: 0;
      height: calc(100% - 5em);
      width: 100%;

      .FiltersHandler__content {
        flex-direction: column;
        width: 100%;
        overflow-y: auto;

        &Entry {
          display: flex;
          flex-direction: column;
          width: 100%;
          align-items: center;
          border-bottom: 1px solid $light-gray;
          background-color: white;
          padding: 0;
          flex-shrink: 0;

          .RangeFilter {
            &__content {
              font-size: 1.5em;
              position: initial;
              top: unset;
              left: unset;
              transform: unset;
              margin-top: 1em;
              width: 100%;
              box-shadow: none;
              border-radius: 0;
              background-color: transparent;
            }

            &__title {
              width: 100%;
              padding: 1.25em;
              font-size: 1.75em;
              justify-content: center;
              flex-shrink: 0;
            }
          }

          &.RangeFilter--expanded {
            .RangeFilter__title {
              background-color: rgba(126, 214, 97, 0.1);
            }
          }

          .Multiselect {
            font-size: 1.5em;
            position: initial;
            top: unset;
            left: unset;
            transform: unset;
            margin-top: 1em;
            width: 100%;
            box-shadow: none;
            border-radius: 0;
            background-color: transparent;
            flex-shrink: 0;

            input {
              font-size: 1.25em;
            }

            &Filter {
              &__title {
                width: 100%;
                padding: 1.25em;
                font-size: 1.75em;
                justify-content: center;
                flex-shrink: 0;
              }
            }
          }

          &.MultiselectFilter {
            &--expanded {
              .MultiselectFilter__title {
                background-color: rgba(126, 214, 97, 0.1);
              }
            }
          }
        }

        svg {
          display: none;
        }

        .FiltersHander__submitButton {
          margin-top: 2em;
          margin-bottom: 2em;
          font-size: 1.5em;
        }
      }
    }
  }
}
