@import '../../styles/variables';

.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: white;
  color: $dark-green;
  font-weight: 900;
  font-size: 0.8em;
  padding: 1em 3em;
  letter-spacing: 0.5px;
  cursor: pointer;

  &--disabled {
    color: white;
    background-color: $light-gray;
    cursor: initial;
  }

  &--shadow {
    box-shadow: $shadow;
  }

  &--shadowOnHover {
    &:hover {
      box-shadow: $shadow;
    }
  }
}
