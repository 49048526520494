@import '../../../styles/variables';

.RangeFilter {
  position: relative;

  &--expanded {
    background-color: rgba(126, 214, 97, 0.1);
  }

  &--selected {
    .RangeFilter__title {
      color: black;
    }
  }

  &__title {
    display: flex;
    align-items: baseline;
    border-radius: 5px;
  }

  &__content {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%) translateY(calc(100% + 1.5em));
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
    padding: 2em 3em 3.5em 3em;
    width: 30em;

    &Title {
      margin-bottom: 3em;
      font-weight: bold;
      letter-spacing: 1.5px;
    }

    .Range {
      margin-bottom: 4em;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
