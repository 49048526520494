@import '../styles/variables';

.Layout__content > .Signup {
  padding-top: 0;
  min-height: 100vh;
}

.Signup {
  display: flex;
  flex-direction: column;
  background-image: url('../../assets/images/background_login.png');
  background-size: cover;
  background-repeat: no-repeat;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    justify-content: center;
    flex-grow: 1;
  }

  &__title {
    display: flex;
    flex-direction: column;
    font-weight: 900;
    font-family: Montserrat;
    text-align: center;
    text-shadow: 0 2px 15px #18894c;
    color: white;
    margin-bottom: 5em;
    z-index: 1;

    &Top {
      font-size: 3.75em;
      letter-spacing: 0.8px;
    }

    &Bottom {
      font-size: 5.5em;
      letter-spacing: 1.25px;
      z-index: 2;
    }
  }

  &__womanPicture {
    height: 75%;
    position: absolute;
    top: 10%;
    left: 45%;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    margin-bottom: 1em;

    &Top,
    &Middle {
      display: flex;
      margin-bottom: 1.5em;
    }

    .Input {
      margin-right: 1.5em;

      &:last-child {
        margin-right: 0;
      }
    }

    &Bottom {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 1.5em;

      &Left {
        display: flex;
        flex-direction: column;

        .RadioButton {
          margin-bottom: 0.5em;

          &__label {
            color: #29a260;
            font-size: 0.75em;
          }

          a {
            text-decoration: underline;
          }
        }
      }

      &Right {
        color: #29a260;
        font-weight: 700;
        font-size: 0.75em;
      }
    }

    .Button {
      width: 12.5em;
    }
  }

  &__loginLink {
    font-family: Montserrat;
    color: white;
    text-shadow: 0 2px 15px black;
    font-size: 0.875em;
    z-index: 2;
    cursor: pointer;

    a {
      font-weight: 900;
      text-decoration: none;
      color: white;

      &:hover {
        text-decoration: underline;
      }

      &:visited {
        color: inherit;
      }
    }
  }

  @media (max-width: 1000px) {
    &__content {
      padding: 0 5%;
    }

    &__title {
      margin-top: 5em;
      margin-bottom: 2em;

      &Top,
      &Bottom {
        font-size: 1.5em;
      }
    }

    &__womanPicture {
      display: none;
    }

    &__form {
      &Top,
      &Middle {
        flex-direction: column;
        width: 100%;
        margin-bottom: 0;

        .Input,
        .Button {
          margin-right: 0;
          margin-bottom: 1em;
          width: 100%;
        }
      }
    }
  }
}
