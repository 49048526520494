@import '../../styles/variables';

.CoachingClassesList {
  $list-width: 60%;

  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100%;

  &__loader {
    width: $list-width;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }

  &__list {
    display: flex;
    flex-direction: column;
    padding: 2em;
    width: $list-width;
    height: 100%;
    overflow-y: auto;
    flex-grow: 1;

    &Title {
      font-size: 2em;
      font-weight: bold;
      margin-bottom: 1em;
    }

    &Filters {
      margin-bottom: 2em;

      &Row {
        .Button {
          display: none;
        }

        flex-shrink: 0;
        margin-bottom: 1em;
      }
    }

    &Content {
      display: flex;
      flex-direction: column;
      align-items: center;

      &Placeholder {
        font-size: 0.9em;
        color: $gray;
        text-align: center;
        width: 50%;

        a {
          text-decoration: underline;
        }
      }
    }
  }

  &__map {
    width: calc(100% - #{$list-width});
    height: calc(100vh - 5rem);
    position: fixed;
    top: 5rem;
    right: 0;

    &Content {
      height: 100%;
      width: 100%;
    }

    .Button {
      display: none;
    }
  }

  @media (min-width: 1800px) {
    $list-width: 50%;

    &__list,
    &__loader {
      width: $list-width;
    }

    &__map {
      width: calc(100% - #{$list-width});
    }
  }

  @media (min-width: 2200px) {
    &__list {
      width: 45%;
    }

    &__map {
      width: 55%;
    }
  }

  @media (max-width: 1000px) {
    &__list {
      width: 100%;

      &Filters {
        margin-bottom: 0;

        &Row {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .Button {
            background-color: $dark-green;
            color: white;
            display: flex;
          }
        }
      }
    }

    .CoachingClassPreviewHorizontal {
      flex-direction: column;
      min-height: fit-content;

      &__photo {
        width: 100%;
        margin-bottom: 1em;
      }

      &__infos {
        flex-direction: column-reverse;

        &Right {
          flex-direction: row-reverse;
          margin-bottom: 1em;
        }
      }
    }

    &__map {
      display: none;

      &--mobileVisible {
        display: flex;
        width: 100%;
        z-index: 3;
      }

      .Button {
        display: flex;
        top: 2em;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        background-color: $dark-green;
        color: white;
        box-shadow: $shadow;
      }
    }
  }
}
