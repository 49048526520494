@import '../styles/variables';

$header-height: 9em;

.Layout__content > .Home {
  padding-top: 0;
}

.Home {
  min-height: 100%;

  .Header {
    height: $header-height;
  }

  &__search {
    position: relative;
    height: 100vh;
    background-image: url('../../assets/images/background_home_search.png');
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 10em;

    @media (max-width: 1700px) {
      font-size: 0.9em;

      .Home__searchTextSecond {
        left: 72%;
        top: 45%;
      }
    }

    @media (max-width: 1600px) {
      font-size: 0.8em;

      .Home__searchTextSecond {
        left: 70%;
        top: 45%;
      }
    }

    @media (max-width: 1450px) {
      font-size: 0.7em;

      .Home__searchTextSecond {
        left: 73%;
        top: 45%;
      }
    }

    @media (max-width: 1300px) {
      font-size: 0.65em;

      .Home__searchTextSecond {
        left: 78%;
        top: 45%;
      }
    }

    &Image {
      z-index: 2;
      position: absolute;
      left: 35%;
      top: 15%;
      max-height: 85%;
      height: auto;
      max-width: 40%;

      img {
        pointer-events: none;
        user-select: none;
        max-height: 85vh;
        height: auto;
      }
    }

    &Text {
      &--mobileVisible {
        display: none;
      }

      &FirstLine {
        user-select: none;
        pointer-events: none;
        text-align: left;
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 2.5em;
        color: white;
        text-shadow: 0 2px 15px #18894c;
      }

      &SecondLine {
        user-select: none;
        pointer-events: none;
        text-align: left;
        font-family: 'Montserrat';
        font-weight: 900;
        font-size: 5em;
        color: white;
        text-shadow: 0 2px 15px #18894c;
      }

      &First {
        position: absolute;
        z-index: 3;
        top: 20%;
        left: 33%;
        transform: translateX(-50%);
        text-align: center;
      }

      &Second {
        z-index: 3;
        position: absolute;
        top: 40%;
        left: 68%;
        transform: translateX(-50%);
        text-align: center;
      }
    }

    &Bar {
      position: absolute;
      z-index: 3;
      bottom: 18%;
      left: 50%;
      transform: translateX(-50%);
      width: 60%;

      input {
        margin-left: 1em;
        border: none;
        border-bottom: 1px solid transparent;
        line-height: 2em;
        width: 100%;

        &:focus {
          border-bottom: 1px solid $dark-green;
        }
      }

      &Separator {
        width: 1px;
        height: 100%;
        background: $light-gray;
        margin: 0 2em;
      }

      &Content {
        padding: 1em 1.5em;
        background-color: white;
        border-radius: 5px;
        height: 5em;
        box-shadow: 0 2px 23px 0 #21a05b;
        display: flex;
        align-items: center;
        margin-bottom: 1em;
      }

      .InputWithLabel {
        border: none;
        outline: none;
        width: 100%;
        text-overflow: ellipsis;

        &__input {
          border: none;
        }
      }

      &Location {
        display: flex;
        align-items: center;
        flex-grow: 1;

        div:last-child {
          flex-grow: 1;
        }
      }

      &Sport {
        display: flex;
        align-items: center;
        flex-grow: 1;
      }

      &Button {
        display: flex;
        align-items: center;
        border-radius: 5px;
        color: white;
        background: $gradient;
        padding: 0 3em;
        height: 100%;
        font-weight: 900;
        margin-left: 2em;
        cursor: pointer;

        i {
          font-size: 1.5em;
          margin-right: 0.25em;
        }
      }
    }
  }

  &__bestClasses {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &Title {
      font-size: 2em;
      font-weight: 300;
      letter-spacing: 1px;
      margin-bottom: 0.5em;
      text-align: center;

      span {
        font-weight: 400;
      }
    }

    &Controls {
      position: absolute;
      right: 10%;
      top: 0;

      svg {
        border-radius: 50%;
        cursor: pointer;
        overflow: hidden;

        &:hover {
          box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
        }

        &:first-child {
          margin-right: 1em;
          transform: rotate(180deg);
        }
      }
    }

    &Content {
      display: flex;
      padding: 2em 0 2em 10%;
      width: 100%;
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      &:after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        pointer-events: none;
        background-image: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 1) 90%
        );
        height: 100%;
        width: 10%;
      }

      a {
        margin-right: 2em;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &SeeMore {
      display: flex;
      align-items: center;
      border-radius: 5px;
      color: white;
      font-size: 0.8em;
      background: $gradient;
      padding: 0.75em 2em;
      font-weight: 900;
      cursor: pointer;

      &:hover {
        box-shadow: $shadow;
      }
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5em;

    &Title {
      font-size: 2em;
      font-weight: 300;
      letter-spacing: 1px;
      margin-bottom: 2.5em;
      text-align: center;

      span {
        font-weight: 400;
      }
    }

    &Content {
      display: flex;
      justify-content: space-between;
      padding: 0 15%;
    }

    &Item {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 0 1em;

      &Icon {
        margin-bottom: 1em;
      }

      &Title {
        font-weight: 500;
        font-size: 1.125em;
        margin-bottom: 1em;
      }

      &Text {
        font-weight: 300;
        font-size: 0.85em;
      }
    }
  }

  &__questions {
    padding: 0 15%;
    margin-bottom: 5em;

    &Content {
      display: flex;
      justify-content: space-between;
      border: 4px solid white;
      border-radius: 10px;
      background-color: #b0ffd5;
      box-shadow: 0 5px 20px 0 rgba(77, 186, 127, 0.39);
      padding: 3em 5em;
    }

    &Text {
      font-size: 1.7em;
    }

    &Button {
      display: flex;
      align-items: center;
      border-radius: 5px;
      background-color: white;
      color: $dark-green;
      font-weight: 900;
      font-size: 0.8em;
      padding: 1em 3em;
      letter-spacing: 0.5px;
      cursor: pointer;

      &:hover {
        box-shadow: $shadow;
      }
    }
  }

  &__categories {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow: hidden;
    margin-bottom: 5em;

    &Content {
      width: 100%;
    }

    &Title {
      font-size: 2em;
      font-weight: 300;
      letter-spacing: 1px;
      margin-bottom: 2.5em;
      text-align: center;

      span {
        font-weight: 400;
      }
    }

    .BrainhubCarousel__customArrows {
      height: 40px;
      width: 40px;
      position: absolute;
      z-index: 2;
      background-color: white;
      border-radius: 50%;
      cursor: pointer;
      box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.5);
    }

    .BrainhubCarousel__custom-arrowLeft {
      left: 3em;
      transform: rotate(180deg);
    }

    .BrainhubCarousel__custom-arrowRight {
      right: 3em;
    }

    &Content {
      &Photo {
        margin-right: 1em !important;
        margin-left: 1em !important;
        overflow: hidden;
        border-radius: 10px;
        width: 100%;

        img {
          height: 15em;
          width: 100%;
          object-fit: cover;
          overflow: hidden;
        }
      }
    }
  }

  &__instagram {
    padding: 5em 15% 0 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10em;

    &Title {
      font-size: 2em;
      font-weight: 300;
      letter-spacing: 1px;
      margin-bottom: 2.5em;
      text-align: center;

      span {
        font-weight: 400;
      }
    }

    &Content {
      flex-grow: 1;

      &Photos {
        height: 75vh;
        width: 112vh;
      }

      &Row {
        display: flex;
        height: calc(75vh / 2 - 2em);
        margin-bottom: 2em;
      }

      &Entry {
        margin-right: 2em;
        border-radius: 5px;
        overflow: hidden;
        width: calc(25% - 1.5em);

        &:last-child {
          margin-right: 0;
        }

        &--large {
          width: calc(50% - 1em);
        }

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: top;
        }
      }

      &Button {
        width: 100%;
        display: flex;
        align-items: center;
        color: white;
        justify-content: center;
        background: rgb(152, 181, 197);
        border-radius: 0 0 5px 5px;
        padding: 1em;
        font-weight: bold;
        font-size: 1.375em;

        svg {
          margin-left: 1em;
          height: 2em;
          width: 2em;

          circle {
            fill: white;
          }

          path {
            fill: rgb(152, 181, 197);
          }
        }
      }
    }
  }

  &__coaches {
    padding: 25em 0 2em 10%;
    background-image: url('../../assets/images/background_home_coaches.png');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10em;
    position: relative;

    &Controls {
      position: absolute;
      right: 10%;
      top: 25em;

      svg {
        border-radius: 50%;
        cursor: pointer;
        overflow: hidden;
        background-color: white;

        &:hover {
          box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
        }

        &:first-child {
          margin-right: 1em;
          transform: rotate(180deg);
        }
      }
    }

    &Title {
      font-size: 2em;
      font-weight: 300;
      letter-spacing: 1px;
      margin-bottom: 2.5em;
      text-align: center;

      span {
        font-weight: 400;
      }
    }

    &Content {
      display: flex;
      margin-bottom: 2em;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      width: 100%;

      &::-webkit-scrollbar {
        display: none;
      }

      &:after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        pointer-events: none;
        background-image: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 1) 90%
        );
        height: 100%;
        width: 10%;
      }
    }

    &Entry {
      margin-right: 6em;
      cursor: pointer;
      padding: 1em;
      text-align: center;

      a {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &:hover {
        .Home__coachesEntryName,
        .Home__coachesEntryLocation {
          opacity: 1;
        }

        .Home__coachesEntryPhoto {
          box-shadow: 0 5px 20px 0 rgba(51, 118, 82, 0.58);
        }
      }

      &:last-child {
        margin-right: 0;
      }

      &Photo {
        border-radius: 50%;
        height: 10em;
        width: 10em;
        object-fit: cover;
        border: 3px solid white;
        margin-bottom: 1em;
      }

      &Name {
        opacity: 0;
        font-size: 0.875em;
        color: #353b50;
        letter-spacing: 2.5px;
        margin-bottom: 0.5em;
      }

      &Location {
        opacity: 0;
        font-size: 0.75em;
        font-weight: 700;
        letter-spacing: 0.5px;

        svg {
          height: 1.25em;
          margin-right: 0.25em;
        }
      }
    }

    &SeeMore {
      display: flex;
      align-items: center;
      border-radius: 5px;
      color: white;
      font-size: 0.8em;
      background: $gradient;
      padding: 0.75em 2em;
      font-weight: 900;
      cursor: pointer;

      &:hover {
        box-shadow: $shadow;
      }
    }
  }

  &__map {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-image: url('../../assets/images/background_home_map.png');
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 10em;
    padding-top: 10em;

    &Content {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 10px;
      background-color: white;
      padding: 2em 1em 1em 1em;
      box-shadow: 0 10px 30px 0 rgba(131, 131, 131, 0.5);
      width: 100%;
      height: 100%;

      &Wrapper {
        position: relative;
        padding: 0 20%;
        width: 100%;
        height: 100%;
        height: 80vh;
      }

      &Map {
        &Search {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          display: flex;
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
          background-color: white;
          padding: 0.5em 0.5em 0.5em 1em;
          border-radius: 5px;
          align-items: center;
          width: 50%;

          svg {
            height: 24px;
            margin-right: 1em;
          }

          .InputWithLabel {
            border: none;
            outline: none;
            width: 100%;
            text-overflow: ellipsis;

            &__input {
              border: none;
            }
          }

          &Left {
            display: flex;
            align-items: center;
            flex-grow: 1;
            margin-right: 2em;
          }

          &Button {
            background-color: $dark-green;
            border-radius: 5px;
            padding: 0.25em;
            color: white;
            font-size: 1.5em;
            display: flex;
            cursor: pointer;
          }
        }
      }
    }

    &Title {
      font-size: 2em;
      font-weight: 300;
      letter-spacing: 1px;
      margin-bottom: 2em;

      span {
        font-weight: 400;
      }
    }
  }

  @media (max-width: 1000px) {
    &__search {
      font-size: 0.6em;

      &Text {
        display: none;

        &--mobileVisible {
          display: flex;
          justify-content: center;
          text-align: center;
          font-family: 'Montserrat';
          font-weight: 900;
          font-size: 5em;
          color: white;
          text-shadow: 0 2px 15px #18894c;
          position: absolute;
          top: 40%;
          transform: translateY(-50%);
          width: 100%;
        }
      }

      &Image {
        display: none;
        max-width: 80%;
        height: unset;
        max-height: unset;
        top: 15%;
        left: 50%;
        transform: translateX(-50%);
        height: auto;

        .parallax-inner {
          display: flex;
          justify-content: center;

          img {
            width: 100%;
          }
        }
      }

      &Bar {
        width: 80%;
        top: unset;
        bottom: 5%;

        &Content {
          height: unset;
          flex-direction: column;
        }

        &Location,
        &Separator,
        &Sport,
        &Button {
          width: 100%;
        }

        .InputWithLabel {
          input,
          select {
            font-size: 1.25em;
          }
        }

        &Button {
          margin-top: 1em;
          height: 3em;
          margin-left: 0;
          justify-content: center;
          font-size: 1.5em;
        }

        &Separator {
          height: 1px;
          margin: 1em 0;
        }
      }
    }

    &__bestClasses {
      &Controls {
        display: none;
      }
    }

    &__map {
      &Content {
        border-radius: 0;
        &Wrapper {
          padding: 0;
        }

        &Map {
          &Search {
            width: 100%;
          }
        }
      }

      &Title {
        text-align: center;
        font-size: 1.5em;
      }

      .Map__zoomControls {
        top: 2.5em;
      }
    }

    &__description {
      &Content {
        flex-direction: column;
      }

      &Item {
        margin-right: 0;
        width: 100%;
        margin-bottom: 4em;
      }
    }

    &__questions {
      padding: 0 5%;

      &Content {
        padding: 1em 1.5em;
        flex-direction: column;
      }

      &Text {
        font-size: 1.25em;
        text-align: center;
        margin-bottom: 1em;
      }

      &Button {
        justify-content: center;
      }
    }

    &__categories {
      &Content {
        .BrainhubCarousel__customArrows {
          display: none;
        }

        &Photo {
          img {
            height: 10em;
          }
        }
      }
    }

    &__coaches {
      padding-top: 17em;
      padding-left: 2em;
      padding-right: 0;
      background-position: bottom;

      &Content {
        width: 100%;
        justify-content: flex-start;
      }

      &Controls {
        display: none;
      }

      &Entry {
        margin-right: 2em;

        &Name,
        &Location {
          opacity: 1;
        }
      }
    }

    &__instagram {
      padding: 0 5%;
      margin-bottom: 5em;

      &Content {
        width: 100%;
        height: auto;

        &Photos {
          height: auto;
          width: 100%;
        }

        &Row {
          justify-content: space-between;
          flex-wrap: wrap;
          height: auto;
          margin-bottom: 0;
        }

        &Entry {
          width: calc(50% - 1em);
          margin-right: 0;
          margin-bottom: 1em;
          flex-shrink: 0;
          height: calc(75vh / 2 - 2em);

          &--large {
            width: 100%;
            flex-shrink: 0;
          }
        }
      }
    }
  }
}
