@import '../../styles/variables';

.CoachSignupInfos {
  padding-bottom: 2em;

  &__title {
    font-weight: 900;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $dark-green;
    margin-bottom: 3em;
    text-align: center;
    z-index: 1;
  }

  &__form {
    display: flex;

    flex-direction: column;
    align-items: center;
    z-index: 2;
    margin-bottom: 1em;

    &Top,
    &Middle {
      display: flex;
      margin-bottom: 1.5em;
    }

    .Input {
      margin-right: 1.5em;

      &:last-child {
        margin-right: 0;
      }
    }

    &Bottom {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 1.5em;

      &Left {
        display: flex;
        flex-direction: column;

        .RadioButton {
          margin-bottom: 0.5em;

          &__label {
            color: #29a260;
            font-size: 0.75em;
          }
        }
      }

      &Right {
        color: #29a260;
        font-weight: 700;
        font-size: 0.75em;
      }
    }

    .Button {
      width: 12.5em;
    }
  }

  &__loginLink {
    font-family: Montserrat;
    color: white;
    text-shadow: 0 2px 15px black;
    font-size: 0.875em;
    z-index: 2;
    cursor: pointer;
    text-align: center;

    a {
      font-weight: 900;
      text-decoration: none;
      color: white;

      &:hover {
        text-decoration: underline;
      }

      &:visited {
        color: inherit;
      }
    }
  }

  @media (max-width: 1000px) {
    &__form {
      padding: 0 5%;

      &Top,
      &Middle {
        flex-direction: column;
        width: 100%;
        margin-bottom: 0;

        .Input,
        .Button {
          margin-right: 0;
          margin-bottom: 1em;
          width: 100%;
        }
      }
    }
  }
}
