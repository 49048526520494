.Optins {
  &__title {
    margin-bottom: 3em;
    font-size: 2em;
    font-weight: bold;
  }

  .Switch {
    margin-bottom: 1.5em;
  }
}
