@import '../../../styles/variables';

.MessageForm {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100vw;
  background-color: rgba(247, 247, 247, 0.8);
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7em 25em;

  &__close {
    position: absolute;
    top: 2em;
    right: 2em;
    width: 1.5em;
    height: 1.5em;
    cursor: pointer;

    svg {
      width: 100%;
      height: auto;
    }
  }

  &__content {
    position: relative;
    background-color: white;
    max-width: 50em;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.26);
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    flex-direction: column;
    padding: 2em 5em 5em 5em;
    overflow-y: auto;

    &Header {
      $coach-image-width: 9em;
      position: relative;
      display: flex;
      padding: 2em 0;
      border-bottom: 1px solid $light-gray;
      margin-bottom: 2em;
      flex-shrink: 0;

      &Coach {
        height: $coach-image-width;
        width: $coach-image-width;
        overflow: hidden;
        border-radius: 50%;
        border: 3px solid white;
        background-color: white;
        box-shadow: 0 5px 20px 0 rgba(76, 76, 76, 0.36);
        margin-right: 2em;
        flex-shrink: 0;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      &Desc {
        display: flex;
        flex-direction: column;

        &Coach {
          color: $gray;
          letter-spacing: 1px;
          text-transform: uppercase;
          font-size: 0.9em;
          font-weight: bold;
          margin-bottom: 0.75em;
          display: flex;
          flex-direction: column;

          span {
            margin-top: 0.5em;
            color: #485b6d;
            font-size: 0.75em;
            font-weight: bold;
            font-style: italic;
            letter-spacing: 0.55px;
          }
        }

        &Title {
          font-size: 2.75em;
          margin-bottom: 0.25em;
        }

        &Rating {
          display: flex;
          align-items: center;
          color: $dark-green;
          font-size: 1.1em;
          margin-bottom: 0.5em;

          &Value {
            font-weight: 900;
            margin-right: 0.5em;
            letter-spacing: 1px;
          }

          .react-rater {
            font-size: 1.1em;
            letter-spacing: 4px;
            margin-right: 0.5em;
          }

          &Feedbacks {
            font-size: 0.75em;
            letter-spacing: 0.65px;
            font-weight: 500;
            text-decoration: underline;
            margin-right: 2em;

            a,
            a:visited {
              color: $dark-green;
            }
          }

          &Networks {
            a {
              margin-right: 0.75em;
            }
          }
        }

        &Location,
        &Date {
          display: flex;
          font-weight: bold;
          color: $gray;
          align-items: center;
          margin-bottom: 0.5em;
          text-transform: capitalize;

          svg {
            height: 1em;
            width: auto;
            margin-right: 0.5em;
          }
        }
      }

      &Price {
        width: 20%;
        height: 100%;

        &Content {
          position: absolute;
          right: -5em;
          top: 3em;
          background-color: $dark-green;
          color: white;
          padding: 1em 1.5em;
          border-radius: 5px 0 0 5px;
          width: 12em;

          span {
            font-size: 2em;
            font-weight: bold;
            letter-spacing: 0;
          }
        }
      }
    }

    &Body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      align-items: center;

      &Title {
        color: $gray;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: bold;
        text-align: center;
        margin-top: 0.5em;
        margin-bottom: 1.5em;
      }

      &Messages {
        flex-direction: column;
        margin-bottom: 2em;
        font-size: 0.8em;
        color: $gray;
        text-align: center;

        &Entry {
          margin-bottom: 1em;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &Message {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        min-height: 10em;
        margin-bottom: 1em;
        border: 1px solid $light-gray;
        border-radius: 5px;
        overflow: hidden;
        width: 100%;

        textarea {
          padding: 1em;
          width: 100%;
          flex-grow: 1;
          resize: vertical;
          border: none;
          outline: none;
        }
      }

      .Button {
        background: $gradient;
        color: white;
        width: 20em;
      }
    }
  }

  @media (max-width: 1000px) {
    padding: 0;

    &__content {
      &Header {
        flex-direction: column;
        align-items: center;

        &Price {
          &Content {
            width: auto;
          }
        }

        &Coach {
          margin-right: 0;
          margin-bottom: 1em;
        }

        &Desc {
          align-items: center;
          text-align: center;
        }
      }

      &Body {
        &Submit {
          padding-bottom: 5em;
        }
      }
    }
  }
}
