@import '../../styles/variables';

.Switch {
  $inner-width: 1.5em;

  display: flex;
  align-items: center;
  cursor: pointer;

  &__switch {
    display: flex;
    position: relative;
    border: 1px solid $light-gray;
    border-radius: 6px;
    background-color: white;
    padding: 2px;
    width: calc(#{$inner-width} * 2 + 10px);
    height: calc(#{$inner-width} + 6px);
    margin-right: 1em;

    &Inner {
      transition: left 0.1s, background-color 0.1s;
      position: absolute;
      left: calc(100% - #{$inner-width} - 2px);
      width: $inner-width;
      height: $inner-width;
      border-radius: 4px;
      color: white;
      background-color: #ff6767;
      display: flex;
      align-items: center;
      justify-content: center;

      svg path {
        fill: white;
        height: 100%;
        width: auto;
      }
    }
  }

  &__label {
    letter-spacing: 0.8px;
  }

  &--on {
    .Switch__switch {
      &Inner {
        left: 2px;
        background-color: $dark-green;
      }
    }
  }
}
