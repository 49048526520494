.Layout {
  min-height: 100%;
  height: 100%;
  $header-height: 5rem;
  display: flex;
  flex-direction: column;

  .Header {
    height: $header-height;
  }

  &__content {
    flex-grow: 1;

    & > div {
      padding-top: $header-height;
      min-height: 100vh;
    }
  }
}
