@import '../../styles/variables';

.Input {
  padding: 0.75em;
  width: 17em;
  box-shadow: 0 2px 23px 0 $dark-green;
  background-color: white;
  border-radius: 5px;

  input {
    border: none;
    outline: none;
    width: 100%;
    font-family: Lato;

    &::placeholder {
      font-size: 0.8em;
      letter-spacing: 0.9px;
      font-weight: 500;
      color: $gray;
    }
  }
}
