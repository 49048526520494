@import '../../styles/variables';

.CoachProfile {
  max-width: 50em;

  &__title {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 0.5em;
  }

  &__subtitle {
    width: 100%;
    font-size: 1.25em;
    color: $gray;
    margin-bottom: 3em;
  }

  &__form {
    &Row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 2em;

      &--alignedTop {
        align-items: flex-start;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .InputWithLabel {
        flex-grow: 1;
        max-width: 23em;
        margin-right: 2em;

        &:last-child {
          margin-right: 0;
        }
      }

      .Multiselect {
        position: relative;
        transform: unset;
        flex-grow: 1;
        max-width: 23em;
        margin-right: 2em;
      }

      .RadioButton {
        flex-grow: 1;
        max-width: 23em;
      }
    }

    &Location {
      & > div {
        width: 100%;
      }
    }

    &Address {
      margin-bottom: 2em;
      width: 100%;
    }

    &PasswordChange {
      font-size: 0.75em;
      color: $dark-green;
      text-decoration: underline;
      letter-spacing: 0.3px;
      margin-top: 0.25em;
      cursor: pointer;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4em;

    &Submit {
      background: $gradient;
      color: white;
      margin-bottom: 1em;
    }

    &DeleteAccount {
      color: gray;
      font-size: 0.75em;
      text-decoration: underline;
      letter-spacing: 0.3px;
      cursor: pointer;
    }
  }

  &__photo {
    height: 7em;
    width: 7em;
    border-radius: 50%;
    border: 3px solid white;
    background-color: white;
    position: relative;
    margin: auto;
    margin-bottom: 3em;
    box-shadow: $shadow;

    input[type='file'] {
      display: none;
    }

    &Save {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 0;
      right: 0;
      height: 2em;
      width: 2em;
      background-color: white;
      border-radius: 50%;
      box-shadow: $shadow;
      cursor: pointer;

      svg {
        height: 1em;
        width: auto;

        path {
          fill: $dark-green;
        }
      }
    }

    &Upload {
      position: absolute;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 0.75em;
      font-weight: bold;
      text-shadow: $shadow;
      padding: 1em;
      height: 100%;
      border-radius: 50%;
      color: white;
      width: 100%;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.1);
      cursor: pointer;

      &:hover {
        opacity: 1;
        transition: opacity 0.1s;
      }
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  @media (max-width: 1000px) {
    &__photoUpload {
      opacity: 1;
    }

    &__form {
      &Row {
        flex-direction: column;
        margin-bottom: 0;
        align-items: center;

        .InputWithLabel {
          margin-right: 0;
          margin-bottom: 2em;
          width: 100%;
        }
      }
    }

    &__actions {
      margin-top: 2em;
    }
  }
}
