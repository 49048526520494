@import '../../styles/variables';

.InputWithLabel {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  &--disabled {
    input,
    textarea {
      background-color: white;
      color: $gray;
    }
  }

  &__label {
    margin-bottom: 0.75em;
    text-transform: uppercase;
    font-size: 0.75em;
    letter-spacing: 0.9px;
  }

  &__input {
    padding: 0.5rem 1rem;
    border-radius: 5px;
    border: 1px solid $light-gray;
    overflow: hidden;

    &--nopadding {
      padding: 0;
    }

    &Wrapper {
      display: flex;
      flex-direction: column;
    }

    input,
    textarea,
    select {
      font-size: 0.875em;
      letter-spacing: 1px;
      border: none;
      outline: none;
      width: 100%;
      padding: 0;
      font-family: Lato;
    }

    textarea {
      min-height: 10em;
      font-family: Lato;
      resize: none;
    }

    select {
      padding: 0.5rem 1rem;
      background-color: white;
    }
  }
}
