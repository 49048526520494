@import '../../styles/variables';

.CoachSignupContact {
  &__title {
    font-weight: 900;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $dark-green;
    margin-bottom: 3em;
    text-align: center;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    margin-bottom: 1em;

    &Row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 1em;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .Input {
      margin-right: 1.5em;

      &:last-child {
        margin-right: 0;
      }

      input::placeholder {
        text-transform: uppercase;
      }
    }

    &Address {
      margin-bottom: 1em;
      width: 100%;

      &.Input {
        margin-right: 0;
      }
    }

    .Button {
      width: 12.5em;
    }

    .rw-datetime-picker {
      width: 17em;
    }
  }

  @media (max-width: 1000px) {
    padding: 0 5%;

    &__form {
      display: flex;
      flex-direction: column;

      &Row {
        flex-direction: column;
        margin-bottom: 0;
        align-items: center;

        .Input,
        .rw-datetime-picker {
          margin-right: 0;
          margin-bottom: 1em;
          width: 100%;
        }
      }
    }
  }
}
